import {useHistory} from 'react-router-dom';
import React from 'react';
import styles from './index.module.less';
import {PhoneOutlined} from '@ant-design/icons'

const Footer = (props) => {

    const history = useHistory()

    return <div className={`${styles.footer_wrapper}`}>
        <div className={styles.links_wrapper}>
            <div onClick={() => history.push('/cooperate')}>商业合作</div>
            <div className={styles.split_line}/>
            <div onClick={() => history.push('/about')}>关于我们</div>
            <div className={styles.split_line}/>
            <div onClick={() => history.push('/join')}>加入我们</div>
        </div>

        <a href="tel:400-700-5199" style={{color: '#fff'}}>
            <div className={styles.phone_wrapper}>
                <PhoneOutlined className={styles.phone}/>
                商务合作：0531-5862 6000
            </div>
        </a>

        <div className={styles.qrcode_wrapper}>
            <img src={'/img/qrcode.jpg'} className={styles.qrcode}/>
            <div className={styles.contact_wrapper}>
                <div className={styles.title}>投诉邮箱：</div>
                <div>toplion@toplion.com.cn<br/>您对我们的产品、服务有任何不满均可投诉</div>
                <div className={styles.title} style={{marginTop: '15px'}}>联系地址：</div>
                <div>山东省济南市高新区经十路7000号汉峪金融 商务中心A6-4号楼19层</div>
            </div>
        </div>
        <div className={styles.beian}>
            <a target={'blank'} href={'https://beian.miit.gov.cn/'}>鲁ICP备15004431号-1</a>

            <a style={{marginLeft: 16}} target={'blank'}
               href={'https://beian.mps.gov.cn/#/query/webSearch?code=37010102000850'}>
                <img src={'/img/ga.png'}
                     style={{width: 16, height: 16}}/>
                鲁公网安备37010102000850号
            </a>
        </div>
    </div>;
};

export default Footer;
