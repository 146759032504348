import React from 'react';
import styles from '../pages/mobile/join/index.module.less';
import NewsStyle from './index.module.less';
import Video from "../components/newVideo";

//解决方案
export const SOLUTIONS = [
    {
        title: '智慧网络',
        type: 'net',
        to: '/solutions/net',
        banner: '/img/solution/zhihuiwangluo/banner.png',
        banner_M: '/img/solution/zhihuiwangluo/banner_M.png',
        title_long: '校园网络解决方案',
        description: '覆盖全校有线无线一体化网络，为校园的数字化建设、各项业务开展、师生日常教学提供服务。',
        fanganzhengtijiagou: '/img/solution/zhihuiwangluo/fanganzhengtijiagou.png',
        shujukeshihuadaping: [{
            title: '一体化校园网络运营',
            description: '实现实时在线学生及教师人数、实时手机/电脑在线终端数量、行为轨迹、AP点位数量、上下行流量速率、实时会话、校区在线热力图、关键词搜索热度、场所接入人数、数据预警等多维度可视化分析及呈现。',
            img: '/img/solution/zhihuiwangluo/shujukeshihuadaping.png',
        }],
        fanganjiazhiliangdian: [
            {
                title: '承载能力',
                description: '基于目前最先进的WiFi6网络建设标准，全面建设覆盖全校的WiFi6网络，满足校园内高密人群的大负荷上网需求。',
            }, {
                title: '融合网关',
                description: '基于国际标准Radius\\DHCP\\DNS等标准协议自主研发的认证、计费、管理、运营、维护、服务的宽带业务支撑运营管理平台。',
            }, {
                title: '安全保障',
                description: '建设同步规划、同步建设、同步运营的网络安全体系，并实现教学办公网络和生活网络的有效隔离，建立有效的网络安全机制。',
            }, {
                title: '成本控制',
                description: 'BOT建设模式，提供全套项目解决方案，专业建设团队进行网络架构搭建，实现建设、运营、运维、客服为一体的全周期服务。',
            }]
    },
    {
        title: '智慧生活',
        type: 'live',
        to: '/solutions/live',
        banner: '/img/solution/zhihuishenghuo/banner.png',
        banner_M: '/img/solution/zhihuishenghuo/banner_M.png',
        title_long: '生活服务解决方案',
        description: '提供直饮水、洗浴、洗衣、吹风、空调、充电桩等生活消费服务，覆盖学生在校生活各方面。',
        fanganzhengtijiagou: '/img/solution/zhihuishenghuo/fanganzhengtijiagou.png',
        shujukeshihuadaping: [{
            title: '一站式生活服务运营',
            description: '实现全国项目分布图、累计消费金额及消费趋势、实时消费信息、充值总金额及充值趋势、用户总数、设备状态检测（总数/在线/离线/使用中等）、项目总数及各项目精细化数据等多维度可视化分析及呈现。',
            img: '/img/solution/zhihuishenghuo/shujukeshihuadaping.png',
        }],
        fanganjiazhiliangdian: [
            {
                title: '投建运营服务一体化',
                description: '扩展和延长了传统项目的业务链条，将项目服务的范围向前扩展到投融资环节，向后扩展到项目的运营维护环节。',
            }, {
                title: '整合多品类硬件设备',
                description: '在硬件坚持自研的同时，与不同硬件厂商联合开发，统一设备协议标准，适配各类生活服务场景，实现平台统一化管理。',
            }, {
                title: '以数据指导运营工作',
                description: '以数据运营思维构建管理平台，通过数据可视化分析，使运营工作更加有数据指导，有工具抓手，实现精准化运营。',
            }, {
                title: '完善的服务支撑体系',
                description: '公司提供7*24小时不间断的平台运维服务，协同客服、运维、技术的多方联动，以最快的速度解决用户问题，提升用户满意度。',
            }]
    },
    {
        title: '智慧零售',
        type: 'sell',
        to: '/solutions/sell',
        banner: '/img/solution/zhihuilingshou/banner.png',
        banner_M: '/img/solution/zhihuilingshou/banner_M.png',
        title_long: '智慧零售解决方案',
        description: '集社交、购物、助学、创业为一体的数字化体验平台，提供线上商城与线下零售柜服务模式。',
        fanganzhengtijiagou: '/img/solution/zhihuilingshou/fanganzhengtijiagou.png',
        yingjianchanpintushi: [
            '/img/solution/zhihuilingshou/yingjianchanpintushi_01.png',
            '/img/solution/zhihuilingshou/yingjianchanpintushi_02.png'],
        fanganjiazhiliangdian: [
            {
                title: '以数据预判需求',
                description: '平台采集业务全链路的实时数据，并通过数据分析建立产品及用户画像，将消费行为数字化，以数据预判需求。',
            }, {
                title: '供应链多元整合',
                description: '打造非遗、网红、时令、地标四类创意产品，产品供给由生产厂家直接到终端，每个环节皆可溯源，食品安全有保障。',
            }, {
                title: '突破距离，开启“服务最后一公里”',
                description: '基于面对面的有温度、有信任度的社交关系，招募学生兼职配送员，统一配送给楼长，由楼长送到寝室，服务面对面。',
            }, {
                title: '赋能校园创业，助力文化传播',
                description: '为创业者提供启动资金、助学岗位。专属校园IP产品定制，赞助校园庆典、大型会议、音乐节,创造校园移动化文化符号。',
            }]
    },
    {
        title: '新一代一卡通',
        type: 'card',
        to: '/solutions/card',
        banner: '/img/solution/yikatong/banner.png',
        banner_M: '/img/solution/yikatong/banner_M.png',
        title_long: '新一代一卡通解决方案',
        description: '提供统一身份认证、统一聚合支付能力，实现门禁、食堂、超市、生活服务等场景一卡通用。',
        fanganzhengtijiagou: '/img/solution/yikatong/fanganzhengtijiagou.png',
        shujukeshihuadaping: [{
            title: '多业务融合呈现',
            description: '实现一卡通全局生态图、师生人数、校区数、院系数、专业数、校园卡统计及使用现状、应用场景及频次、各场景通行趋势、各品类消费趋势、维修排名、充值信息、满意度评价等多维度可视化分析及呈现。',
            img: '/img/solution/yikatong/shujukeshihuadaping.png',
        }],
        fanganjiazhiliangdian: [
            {
                title: '卡码脸币一体化 提升用户体验',
                description: '支持实体卡、二维码、人脸识别、数字人民币等多样化介质，融合线上线下场景，提升用户使用体验。',
            }, {
                title: '智能服务融合 跨业务调度赋能',
                description: '基于物联网技术及服务接入鉴权，使支付、认证、卡务、账务实现跨业务调度，提高应用协调调度能力。',
            }, {
                title: '智能数据治理 搭建决策平台',
                description: '借助数据治理技术，对校园一卡通各场景进行全量、全要素智能整合分析，提供决策依据。',
            }, {
                title: '智能运营管理 打造敏捷校园',
                description: '基于物联网、人工智能技术，实现从基础设施到业务应用的数字化运营管理，构建全校的智能化运营体系。',
            }]
    },
    {
        title: '智慧能源',
        type: 'energy',
        to: '/solutions/energy',
        banner: '/img/solution/zhihuinengyuan/banner.png',
        banner_M: '/img/solution/zhihuinengyuan/banner_M.png',
        title_long: '智慧能源解决方案',
        description: '围绕水电等场景建设能源管理体系，实现实时监控、指标分析、能源预测、智能运检等。',
        fanganzhengtijiagou: '/img/solution/zhihuinengyuan/fanganzhengtijiagou.png',
        shujukeshihuadaping: [{
            title: '多维度能源管控',
            description: '实现全局能源应用总览、本年/季度/月/周/日能源总用量及人均用量、能源变动趋势、能源分时用量趋势、能源用量排行、各区域能源监测、不同时间段能源用量对比、能源告警等多维度可视化分析及呈现。',
            img: '/img/solution/zhihuinengyuan/shujukeshihuadaping.png',
        }],
        fanganjiazhiliangdian: [
            {
                title: '水电计量，实时采集',
                description: '通过实时采集水电数据，帮助高校做出更有效的能源管理决策，从而达到节能减排的目标。',
            }, {
                title: '指标分析，用量趋势',
                description: '综合能源消耗、设备状态、环境因素等多维度数据,为高校提前布局、调整能源策略提供有力依据。\n',
            }, {
                title: '成本核算，节能呈现',
                description: '凭借高科技手段，精细化管理实现节能降耗；实时监测，及时调整；师生参与，共创节能。',
            }, {
                title: '多级管理，监控告警',
                description: '通过合理分配权责，实现各级高效协同，并对能源使用进行实时监测，有效预防能源浪费和安全事故。',
            }]
    },
    {
        title: '智慧通行',
        type: 'pass',
        to: '/solutions/pass',
        banner: '/img/solution/zhihuitongxing/banner.png',
        banner_M: '/img/solution/zhihuitongxing/banner_M.png',
        title_long: '智慧通行解决方案',
        description: '面向门锁、门禁、访客、场馆预约等场景，实现各场所安全通行，实时掌控设备状态，管控通行权限。',
        fanganzhengtijiagou: '/img/solution/zhihuitongxing/fanganzhengtijiagou.png',
        shujukeshihuadaping: [{
            title: '多场景通行联动',
            description: '实现校内人员数据、今日访客数据、已到访人员数据、校内车辆数据、内部车辆数据、来访车辆数据、场所吞吐量、实时出入记录、实时校内热力圈、场馆预约热度、设备状态等多维度可视化分析及呈现。',
            img: '/img/solution/zhihuitongxing/shujukeshihuadaping.png',
        }],
        fanganjiazhiliangdian: [
            {
                title: '安全管理加强',
                description: '利用现代化的技术手段，替代传统的物理钥匙，提升安全性，减少非法入侵、盗窃等安全事件的发生。',
            }, {
                title: '数据分析决策',
                description: '通过数据分析和挖掘，对每个场景的通行频次分析，提供有价值的信息用于学校决策。',
            }, {
                title: '应用体验便捷',
                description: '通过智能化的门禁、门锁、车闸、访客、场馆预约等设备，实现快速、无接触的通行，管控通行权限。',
            }, {
                title: '管理效率提升',
                description: '将各个场景的设备集成到一个平台上，实时追踪人员通行情况、访客预约情况等。',
            }]
    },
    {
        title: '智慧考勤',
        type: 'attendance',
        to: '/solutions/attendance',
        banner: '/img/solution/zhihuikaoqin/banner.png',
        banner_M: '/img/solution/zhihuikaoqin/banner_M.png',
        title_long: '智慧考勤解决方案',
        description: '融合AI摄像机、门禁等设备，提供有感、无感考勤等解方案，实现考勤、归寝、可视大屏等应用。',
        fanganzhengtijiagou: '/img/solution/zhihuikaoqin/fanganzhengtijiagou.png',
        shujukeshihuadaping: [{
            title: '多视角考勤实现',
            description: '实现多场景考勤数据联动、监控大屏呈现、调取抓拍设备、考勤记录、考勤设备管理、考勤人员信息、考勤时间分布、晚归记录、归寝记录、设备监测、通行统计、异常报警等多维度可视化分析及呈现。',
            img: '/img/solution/zhihuikaoqin/shujukeshihuadaping.png',
        }],
        fanganjiazhiliangdian: [
            {
                title: '提高管理效率',
                description: '简化考勤流程,提高管理效率,减少人力成本和时间成本，通过信息化手段提升校园综合管理能力。',
            }, {
                title: '自动化考勤',
                description: '通过设备联动实现无感考勤，同时监测学生的上课/作息规律，减少学生考勤的繁琐程度。',
            }, {
                title: '数据分析统计',
                description: '对学生考勤/归寝情况进行数据统计和分析，有助于学校了解学生的行为规律,及时发现和解决问题。',
            }, {
                title: '安全保障提升',
                description: '通过无感、门禁和门锁等多种签到的考勤方式,授权人员进入相应的区域,避免非法闯入和窃盗事件的发生。',
            }]
    },
    {
        title: '智慧报修',
        type: 'repairs',
        to: '/solutions/repairs',
        banner: '/img/solution/zhihuibaoxiu/banner.png',
        banner_M: '/img/solution/zhihuibaoxiu/banner_M.png',
        title_long: '智慧报修解决方案',
        description: '面向校园全场景业务，实现随手拍修、工单管理、调度管理、进度管理、报修评价、服务监督等应用。',
        fanganzhengtijiagou: '/img/solution/zhihuibaoxiu/fanganzhengtijiagou.png',
        shujukeshihuadaping: [{
            title: '多层面报修推进',
            description: '实现工单数量、工单总量、完成数量、完成率、报修区域数据统计、累计报修区域数据统计、报修品类、报修趋势、实时工单、维修人员信息、维修人员排名、维修服务评价等多维度可视化分析及呈现。',
            img: '/img/solution/zhihuibaoxiu/shujukeshihuadaping.png',
        }],
        fanganjiazhiliangdian: [
            {
                title: '简化报修流程',
                description: '发现设施故障随手拍照上传，通过工单管理整合报修信息，确保每个报修事项得到及时响应与处理。',
            }, {
                title: '提高管理制度',
                description: '根据设施故障的紧急程度和维修人员的技能，智能分配维修任务，确保问题得到迅速解。',
            }, {
                title: '统计物料信息',
                description: '提高费用结算的准确性，也提升物料管理的可追溯性，进一步促进高校维修管理的改进和发展。',
            }, {
                title: '优化服务体系',
                description: '通过持续的评价和监督机制，提高维修效率和质量，助力高校校园设施的维护和管理工作。',
            }]
    },
    {
        title: '智慧迎新',
        type: 'welcome',
        to: '/solutions/welcome',
        banner: '/img/solution/zhihuiyingxin/banner.png',
        banner_M: '/img/solution/zhihuiyingxin/banner_M.png',
        title_long: '智慧迎新解决方案',
        description: '提供迎新前、迎新中、迎新后的全方位服务，线上线下联动，提供多维度迎新数据可视化服务。',
        fanganzhengtijiagou: '/img/solution/zhihuiyingxin/fanganzhengtijiagou.png',
        shujukeshihuadaping: [{
            title: '多样化迎新开展',
            description: '实现新生报道总人数、单日报道人数及报道率、学院报道人数及报道率、到站分布及人数、全国生源分布、男女比例、年龄分布、政治面貌、生源学校、民族统计、培养层次等多维度可视化分析及呈现。',
            img: '/img/solution/zhihuiyingxin/shujukeshihuadaping.png',
        }],
        fanganjiazhiliangdian: [
            {
                title: '线上线下互动迎新，全流程覆盖',
                description: '涵盖迎新前、中、后所有环节，可根据学校需要灵活配置流程，使得新生入学报到变得更加智能、简单。',
            }, {
                title: '严格入学身份鉴定，三照三核验',
                description: '使用智能身份核验技术，支持活体检测，严格比对证件照、学籍照、现场照。支持身份证、护照等智能识别。',
            }, {
                title: '流程数据实时可视，为决策赋能',
                description: '实时统计信息采集、现场报到、公寓入住、缴费等信息，提供多维度统计展示，营造迎新现场氛围。',
            }, {
                title: '新生数据趣味分析，人性化迎新',
                description: '提供二十余项分析维度，从学历、民族、姓名，到生源、生日、平均脸等应用，全面展示新生面貌。',
            }]
    },
    {
        title: '智慧学工',
        type: 'learn',
        to: '/solutions/learn',
        banner: '/img/solution/zhihuixuegong/banner.png',
        banner_M: '/img/solution/zhihuixuegong/banner_M.png',
        title_long: '学工管理解决方案',
        description: '面向精细化学工管理要求，提供流程化、灵活化学工应用平台，结合数据治理输出全面数据报告。',
        fanganzhengtijiagou: '/img/solution/zhihuixuegong/fanganzhengtijiagou.png',
        shujukeshihuadaping: [{
            title: '多专题学工分析',
            description: '实现学工综合数据、流程审批进度及趋势、请销假人数及比例、奖学金发放、荣誉称号获取、违纪处分信息、社团活动开展、勤工俭学信息、综合素质评价、问卷与表单、通知公告等多维度可视化分析及呈现。',
            img: '/img/solution/zhihuixuegong/shujukeshihuadaping.png',
        }],
        fanganjiazhiliangdian: [
            {
                title: '信息化集成平台',
                description: '通过信息化手段构建统一支撑平台，集成已建系统，构建多样化学工应用系统，提升管理效率和服务水平。',
            }, {
                title: '一体化应用平台',
                description: '提供“入学迎新 — 在校学习生活 — 毕业离校”的一体化服务，同时对流程化业务管理，实现学生数据全生命周期管理。',
            }, {
                title: '一站式服务平台',
                description: '构建一站式综合服务大厅，融合所有学工业务应用系统，实现超时空、全场景的日常管理工作开展。',
            }, {
                title: '大数据融合平台',
                description: '融合跨场景的实时数据，实现数据互联互通及共建共享，为创新型数据分析提供精准的数据支撑。',
            }]
    },
    {
        title: '智慧宿管',
        type: 'dormitory',
        to: '/solutions/dormitory',
        banner: '/img/solution/zhihuisuguan/banner.png',
        banner_M: '/img/solution/zhihuisuguan/banner_M.png',
        title_long: '智慧宿管解决方案',
        description: '简化宿管人员操作，可结合智能门锁实现一体化管理，实现入住、归寝、退换宿、卫生、违纪等应用。',
        fanganzhengtijiagou: '/img/solution/zhihuisuguan/fanganzhengtijiagou.png',
        shujukeshihuadaping: [{
            title: '多方向宿管应用',
            description: '实现住宿总人数及入住率、各楼栋/楼层住宿信息、房间总数及空床位数、归寝记录及明细、异常归寝预警、通行记录、考勤记录、违纪情况统计、卫生检查排名、通知公告等多维度可视化分析及呈现。',
            img: '/img/solution/zhihuisuguan/shujukeshihuadaping.png',
        }],
        fanganjiazhiliangdian: [
            {
                title: '一体化管理，便利师生生活',
                description: '实时掌握学校公寓、每个房间及全校学生的住宿详细信息。支持设备联动，实现智能考勤等。',
            }, {
                title: '安全且便捷，保障住宿安全',
                description: '支持按楼栋、楼层、房间的管理及分配。提供可视化管理和智能门禁，对出入人员情况和批量操作。',
            }, {
                title: '数据可视化，实时数据统计',
                description: '实时统计住宿人数、床位、开锁等多种数据，支持归寝状态的实时预警，为管理提供数据和决策依据。',
            }, {
                title: '跨部门协作，提高管理效率',
                description: '能够与迎新、学工、后勤、报修等进行系统对接、数据融合，提升宿舍管理的时效性、降低管理成本。',
            }]
    },
    {
        title: '智慧档案',
        type: 'record',
        to: '/solutions/record',
        banner: '/img/solution/zhihuidangan/banner.png',
        banner_M: '/img/solution/zhihuidangan/banner_M.png',
        title_long: '智慧档案解决方案',
        description: '纸质档案电子化，实现档案上传与检索、OCR及人脸识别、安全水印、去向维护、查阅借阅等应用。',
        fanganzhengtijiagou: '/img/solution/zhihuidangan/fanganzhengtijiagou.png',
        shujukeshihuadaping: [{
            title: '多方位档案管理',
            description: '实现学生档案总数、新增档案份数、借阅档案份数及次数、当前借出档案份数、档案当前借阅状态、档案新增及借阅变化趋势、档案存档类型排行、借阅类型排行、档案去向等多维度可视化分析及呈现。',
            img: '/img/solution/zhihuidangan/shujukeshihuadaping.png',
        }],
        fanganjiazhiliangdian: [
            {
                title: '智能化内容提取',
                description: '支持超大文件上传，自动解析和OCR识别文档内容，全面呈现学生入校、学习、党团、奖惩等档案。',
            }, {
                title: '多维度档案分析',
                description: '可按人员、院系、年级等维度精准检索。提供以图搜档、以人搜档、在线OCR等智能管理工具。',
            }, {
                title: '数据按密级存储',
                description: '支持为档案设定密级，提供操作日志、文档水印等安全措施，确保档案安全数据可靠。',
            }, {
                title: '移动端借阅查阅',
                description: '基于云端技术，可轻松与第三方系统进行数据交换与融合。支持移动端访问，提供进行在线审批。',
            }]
    },
    {
        title: '校友会',
        type: 'alumni',
        to: '/solutions/alumni',
        banner: '/img/solution/xiaoyouhui/banner.png',
        banner_M: '/img/solution/xiaoyouhui/banner_M.png',
        title_long: '校友会系统解决方案',
        description: '一站式校友工作运营管理工具，展示校友风采，提供募捐渠道，盘活校友资源，提升学校影响力。',
        fanganzhengtijiagou: '/img/solution/xiaoyouhui/fanganzhengtijiagou.png',
        shujukeshihuadaping: [{
            title: '多元化校友连接',
            description: '实现校友全国分布、校友会组织数量及类型、热门校友会组织、校友人数、校友认证、校友募捐活动开展及金额、捐赠排行榜、最新校友活动、各届校友分布、明星校友展播等多维度可视化分析及呈现。',
            img: '/img/solution/xiaoyouhui/shujukeshihuadaping.png',
        }],
        fanganjiazhiliangdian: [
            {
                title: '社交网络触达',
                description: '邀请校友回归并加入校友会，并对邀请行为给予贡献度奖励，激发大家去中心化的进行邀请。',
            }, {
                title: '数据统计分析',
                description: '提供多样化数据统计分析维度，通过实时数据统计，查看校友会的组织成果，评价优化校友会组织工作。',
            }, {
                title: '量化校友贡献',
                description: '通过邀请、参与活动、发表内容、为校捐赠等行为，激发校友参与感，促进校友会活跃。',
            }, {
                title: '用户体验升级',
                description: '以用户体验为中心，同时可根据学校的具体需求和要求，为其提供按需定制的服务。',
            }]
    },
    {
        title: '数据治理',
        type: 'data-govern',
        to: '/solutions/data-govern',
        banner: '/img/solution/shujuzhili/banner.png',
        banner_M: '/img/solution/shujuzhili/banner_M.png',
        title_long: '数据治理解决方案',
        description: '构建“横向集成、纵向贯通”的数据治理平台，提供领导驾驶舱、一表通、用户画像、专题报告等服务。',
        fanganzhengtijiagou: '/img/solution/shujuzhili/fanganzhengtijiagou.png',
        shujukeshihuadaping: [{
            title: '全局性数据治理',
            description: '实现领导驾驶舱、全局数据整理与预警、教职工画像、学生画像、学科专题、科研专题、后勤专题、学工专题、教务专题、财务专题、行政专题、资产专题、平安校园专题等多维度可视化分析及呈现。',
            img: '/img/solution/shujuzhili/shujukeshihuadaping.png',
        }],
        fanganjiazhiliangdian: [
            {
                title: '驾驶舱',
                description: '将全校数据可视化呈现,一站式掌握关键数据。拖拽式的版块设计，快速响应个性化的用户需求。',
            }, {
                title: '一表通',
                description: '轻量化的线上办事、填报系统。基于数字底座,实现学校报表数据“一次填报,共享互通”。',
            }, {
                title: '用户画像',
                description: '充分结合教务、学工、后勤、消费领域的相关数据，生成全面、立体、精准的师生画像分析系统。',
            }, {
                title: '专题报告',
                description: '根据管理者关心的需求,快速定制专题报告,订阅发布,多端阅读(移动端、电脑端)。',
            }]
    },
    {
        title: '数字孪生',
        type: 'digital-twin',
        to: '/solutions/digital-twin',
        banner: '/img/solution/shuziluansheng/banner.png',
        banner_M: '/img/solution/shuziluansheng/banner_M.png',
        title_long: '数字孪生解决方案',
        description: '整合校园的空间数据、属性数据与业务数据，实现校园综合态势、安防、环境等可视化、智能化管理。',
        fanganzhengtijiagou: '/img/solution/shuziluansheng/fanganzhengtijiagou.png',
        shujukeshihuadaping: [{
            title: '全景式可视孪生',
            description: '实现校园园区可视化、建筑可视化、结构可视化、场景可视化、校情整体展示、校园安防、校园网络、智慧能源、智慧教学、智慧物联、人才培训、综合服务等多维度可视化分析及立体动态三维效果呈现。',
            img: '/img/solution/shuziluansheng/shujukeshihuadaping.png',
        }],
        fanganjiazhiliangdian: [
            {
                title: '自主研发的引擎',
                description: '面向校园场景、速度快、支持矢量地图、弹性伸缩、持续发布和部署,为高校提供全场景组件及服务能力。',
            }, {
                title: '高精度三维建模',
                description: '具有行业内最高精度的三维建模技术和精美的三维校园场景渲染技术，维护全生命周期的数据。',
            }, {
                title: '多业务场景集成',
                description: '系统实现多场景、多视角的应用,以业务应用场景为基础,进行数据汇聚展示,挖掘数据应用价值。',
            }, {
                title: '开放性',
                description: '具有符合行业标准的API二次开发接口，与学校其他业务系统互联互通,无缝兼容,按需定制的服务能力。',
            }]
    },
    {
        title: '校园门户',
        type: 'portal',
        to: '/solutions/portal',
        banner: '/img/solution/xiaoyuanmenhu/banner.png',
        banner_M: '/img/solution/xiaoyuanmenhu/banner_M.png',
        title_long: '校园门户解决方案',
        description: '一站式服务与管理平台，提供多终端统一入口，聚合校内应用和资讯信息，实现一次登录全局享用。',
        fanganzhengtijiagou: '/img/solution/xiaoyuanmenhu/fanganzhengtijiagou.png',
        xiaoyuanmenhu_web: '/img/solution/xiaoyuanmenhu/web.png',
        xiaoyuanmenhu_app: '/img/solution/xiaoyuanmenhu/app.png',
        fanganjiazhiliangdian: [
            {
                title: '多终端服务',
                description: '提供WEB、APP、公众号H5、微信小程序、钉钉等多类终端的业办公场景,实现功能、权限、数据互通。',
            }, {
                title: '统一身份认证',
                description: '统一身份认证目的在于解决高校实现一次登录，全业务免登即享，解决身份授权中的安全和管理难题。',
            }, {
                title: '统一应用聚合',
                description: '聚合校内多厂商应用，基于低代码引擎快速构建轻应用，灵活快速的响应客户的个性化需求。',
            }, {
                title: '统一平台标准',
                description: '建立应用接入标准规范和校级数据标准，实现多厂商标准接入管理，解决信息孤岛、数据孤岛等问题。',
            }]
    },
];

//首页主营业务
export const HOME_MAIN_BUSINESS = [
    {
        title: '校园网络',
        description: '涵盖宽带认证计费、负载均衡、智能DNS、IPV4/V6双栈运行、防共享、portal平台等应用。',
        img: '/img/solutions_zhihuiwangluo.png',
        type: 'net',
        to: '/solutions/net',
    },
    {
        title: '生活服务',
        description: '涵盖食堂、饮水、洗浴、吹风、洗衣、烘干机、充电桩、自助打印、自助售货柜等场景。',
        img: '/img/solutions_shenghuofuwu.png',
        type: 'live',
        to: '/solutions/live',
    },
    {
        title: '智慧后勤',
        description: '涵盖新一代一卡通、智慧能源、智慧通行、智慧考勤、智慧报修等大后勤应用解决方案。',
        img: '/img/solutions_card.png',
        type: 'card',
        to: '/solutions/card',
    },
    {
        title: '智慧学工',
        description: '涵盖智慧迎新、智慧学工、智慧宿管、智慧档案、校友会等大学工应用解决方案。',
        img: '/img/solutions_zhihuixuegong.png',
        type: 'learn',
        to: '/solutions/learn',
    },
]

//联系我们
export const ALL_COMPANYS = [
    {
        area: '山东(总部)',
        address: '山东省济南市高新区经十路汉峪金融 商务中心A6-4号楼19层',
        mobile: '0531-5862 6000',
    }, {
        area: '山西',
        address: '山西省太原市学府产业园数码港B座A区502',
        mobile: '18135181181',
    }, {
        area: '北京',
        address: '北京市海淀区上地九街九号数码科技广场北楼1层109号',
        mobile: '15811040687',
    }, {
        area: '吉林',
        address: '吉林省长春市净月区GTC环球贸易中心2601室',
        mobile: '13009003299',
    },
];

//热销产品
export const FUSION_GATEWAY = {
    imgs: '/img/gateway.png',
    titless: 'TL-BRAS融合网关系列',
    title: 'TL-BRAS融合网关系列',
    detail: {
        scene: '应用于校园网投资建设、宽带运营解决方案的出口网关设备',
        teses: [
            {
                icon: '/img/hot_call.png',
                content: '高流量吞吐，最高单机可支持100G流量',
            },
            {
                icon: '/img/hot_rate.png',
                content: '支持大二层接入，支持SuperVlan，无缝漫游',
            },
            {
                icon: '/img/face_agreement.png',
                content: '支持Portal认证，支持PPPoE认证，支持Radius无感知认证',
            },
            {
                icon: '/img/hot_malfunction.png',
                content: '支持自定义路由，支持静态路由，支持策略路由',
            },
            {
                icon: '/img/face_record.png',
                content: '支持由Radius下发服务进行选路',
            },
            {
                icon: '/img/hot_silica_gel.png',
                content: '支持流控：可根据应用选路，支持流量7层分析，可自定义应用',
            },
        ]
    }
}

//节水控制
export const WATER_SAVING_PRODUCTS_Temporary = [
    {
        title: '热水一体水控器',
        titless: '热水一体水控器',
        imgs: '/img/pro_hot_water.png',
        img: '/img/img_new_reshuiyitishuikongqi.png',
        scene: '广适用于校园内浴室场景（大集中/小集中/独立卫浴）',
        to: '/product/0',
        version: 'TOP-WT101',
        detail: {
            scene: '广适用于校园内浴室场景（大集中/小集中/独立卫浴）',
            intro: <div>
                模组：一体程序；<br />
                通讯方式： 4G、蓝牙，设备实时互联，在线智能化管理；<br />
                支付方式：微信、支付宝扫码、IC卡消费方式；<br />
                液晶显示屏；<br />
                支持离线、会员钱包等消费模式。
            </div>,
            tese: <div>
                设备故障，自动报警；<br />
                支持费率灵活设置；<br />
                数据在内存卡10万条备份，防止意外丢失；<br />
                防磁设计，避免偷水现象，安全性高；<br />
                全采用硅胶全密封式设计防潮防氧化。

            </div>,
            teses: [
                {
                    icon: '/img/hot_call.png',
                    content: '设备故障，自动报警',
                },
                {
                    icon: '/img/hot_rate.png',
                    content: '支持费率灵活设置',
                },
                {
                    icon: '/img/hundred.png',
                    content: '数据在内存卡10万条备份，防止意外丢失',
                },
                {
                    icon: '/img/hot_malfunction.png',
                    content: '防磁设计，避免偷水现象，安全性高',
                },
                {
                    icon: '/img/hot_silica_gel.png',
                    content: '全采用硅胶全密封式设计防潮防氧化',
                },
            ]
        },
    },
    {
        title: '热水一体预约水控器',
        img: '/img/img_new_reshuiyitiyuyueshuikongqi.png',
        to: '/product/10',
        scene: '广泛适用于校园内浴室场景（大集中/小集中）',
        version: 'ZF-S7602',
        detail: {
            scene: '广泛适用于校园内浴室场景（大集中/小集中）',
            intro: <div>
                模组：一体程序；<br />
                通讯方式： 4G、蓝牙，设备实时互联，在线智能化管理；<br />
                支付方式：微信、支付宝扫码、IC卡消费方式；<br />
                支持手机预约；无需排队；<br />
                液晶显示屏；<br />
                支持离线、会员钱包等消费模式。
            </div>,
            tese: <div>
                支持费率灵活设置；<br />
                设备故障，自动报警；<br />
                防磁设计，避免偷水现象，安全性高；<br />
                全采用硅胶全密封式设计防潮防氧化。<br />
            </div>,
        },
    },
    {
        titless: '直饮水控器',
        imgs: '/img/pro_water.png',
        title: '直饮水控器',
        img: '/img/img_new_zhiyinshuikongqi.png',
        to: '/product/1',
        scene: '广泛适用于校园内各种开水器/饮水台',
        version: 'TOP-WT111',
        detail: {
            scene: '广泛适用于校园内各种开水器/饮水台',
            intro: <div>
                设备程序：分体程序；<br />
                通讯方式：4G、蓝牙，设备实时互联，在线智能化管理；<br />
                支付方式：微信、支付宝扫码、IC卡消费方式；<br />
                液晶显示屏；<br />
                支持离线、会员钱包等消费模式。
            </div>,
            tese: <div>
                支持费率灵活设置；<br />
                数据在内存卡10万条备份，防止意外丢失；<br />
                设备故障自动报警；<br />
                防磁设计，避免偷水现象，安全性高；<br />
                全采用硅胶全密封式设计防潮防氧化。
            </div>,
            teses: [
                {
                    icon: '/img/hot_call.png',
                    content: '设备故障，自动报警',
                },
                {
                    icon: '/img/hot_rate.png',
                    content: '支持费率灵活设置',
                },
                {
                    icon: '/img/hot_malfunction.png',
                    content: '防磁设计，避免偷水现象，安全性高',
                },
                {
                    icon: '/img/hot_silica_gel.png',
                    content: '全采用硅胶全密封式设计防潮防氧化',
                },
                {
                    icon: '/img/hundred.png',
                    content: '数据在内存卡10万条备份，防止意外丢失',
                },
            ]
        },
    }];

//共享服务
export const SHARE_PRODUCTS_Temporary = [
    {
        title: '共享吹风机',
        img: '/img/img_new_gongxiangchuifengji.png',
        to: '/product/2',
        scene: '适用于学生浴室/洗漱间等',
        version: 'TOP-SH101',
        detail: {
            scene: '适用于学生浴室/洗漱间等',
            intro: <div>
                类型：壁挂式；<br />
                通讯方式： 4G全网通、蓝牙 ，设备实时互联，在线智能化管理；<br />
                简单易用：无需下载APP，微信注册扫码即使用，消费价格一目了然；<br />
                在线监控：通过后台可以对所有的设备进行管理和监控；<br />
                每台主控可挂2台；放回卡座自动停止；多档位可调。
            </div>,
            tese: <div>
                静音设计：吹风机采用优势电机，静音远低于国家标准；<br />
                防水设计：可用于浴室等环境；<br />
                安全设计：设有过热自动关机功能，最大程度上避免安全事故；<br />
                断电过载保护：倒计时保护，时间到自动断电；主板过载保护；<br />
                漏电保护：每条线路都加装漏电开关，防止漏电。
            </div>,
        },
    },
    {
        title: '智能电动车充电站',
        img: '/img/img_new_zhinengdiandongchechongdianzhan.png',
        to: '/product/10',
        scene: '广泛应用于小区、商业区、写字楼、工业园、企事业单位等电动车充电场所',
        version: 'TOP-SC101',
        detail: {
            scene: '广泛应用于小区、商业区、写字楼、工业园、企事业单位等电动车充电场所',
            intro: <div>
                一机多控，可以控制10路输出接口；<br />
                扫码充电，手机扫码交费即可充电；<br />
                支持4G移动通讯网络，安装简单便捷；<br />
                支持按时计费或按量计费两种充电模式；<br />
                断电续充，停电来电后自动继续充电。
            </div>,
            tese: <div>
                充电站是由我公司自主研发和生产，通过一机多控、自动断电、电子计数，扫码充电、断电记忆、过载保护等功能，接入充电设备，实现安全充电及充电时间管理。设备通过4G全网通网络，无需铺设通信线路，施工方便快捷，安装调试简单。<br />
                本产品支持10路插座控制，用户通过微信扫码充电，单路功率支持最大1500W，使用更方便。
            </div>,
        },
    },
];

//智能消费
export const CONSUMPTION_LIFE_PRODUCTS_Temporary = [
    {
        title: '台式云智能消费机',
        img: '/img/img_new_xiaofeiji1.png',
        scene: '本产品广泛应用于各学校食堂/超市',
        to: '/product/8',
        version: 'TOP-CS101',
        detail: {
            scene: '本产品广泛应用于各学校食堂/超市',
            intro: <div>
                安卓台式云消费机外观精致高雅、双面大彩屏显示、小屏幕自定义广告、网线 + WIFI +
                4G多种通讯方式，操作简便、数据安全可靠、功能实用，可广泛应用于大型娱乐场所、餐厅、超市、公共餐厅等各种各类收费场所和计费应用项目，可以大幅度的提高支付管理效率。<br />
                云消费机安卓台式云消费机支持刷卡支付、微信支付宝二维码支付、银联云闪付、电子校园卡支付等方式；同时支持关注微信公众号实现充值、挂失、订餐点菜等功能。数据库自动备份、上传，更安全，后台系统界面友好，每天消费数据一目了然，报表统计方便，对账更简单。
            </div>,
            tese: <div>
                面板一（主显面）在使用时，朝向使用人员。该面板包括一块彩屏、键盘；<br />
                面板二（客显面）在使用时，朝向待付款方。该面板包括一个扫码台、一块彩屏；<br />
                在整个消费收款过程中，有三种语音提示，提示内容分别为“请扫码支付”、“支付成功”、“支付失败”；<br />
                智能操作系统、支持计次/定额/自由/菜单消费；<br />
                定额消费：如定额5元，按5元确定。所有人刷卡自动扣5元，固定消费也可以设置早餐5元，中餐8元，晚餐10元，到时候自动切换，无需手动操作；<br />
                计次消费：注册人员信息即可，刷一次记一次，刷卡语音代表刷卡成功，在此基础上可显示限次操作；<br />
                自由消费：是消费多少自由化，本机刷卡语音提示，双面高清大屏，显示卡号、姓名、当前消费额以及余额；<br />
                菜单消费：设置好菜单明细，输入编码按确定，即可自动计算，刷卡扣款语音提示。
            </div>,
        },
    },
    {
        title: '智能挂式双屏消费机',
        img: '/img/img_new_xiaofeiji3.png',
        to: '/product/10',
        scene: '本产品广泛应用于各学校食堂/超市',
        version: 'TOP-CS102',
        detail: {
            scene: '本产品广泛应用于各学校食堂/超市',
            intro: <div>
                安卓智能双屏，智慧食堂团餐收银终端设备；<br />
                刷卡支付：内置NFC模块，可读取IC卡、常见的校园卡、智能卡，读卡迅速，反应灵敏；<br />
                扫码支付：内置硬解码扫码窗，即插即用，只需打开手机微信、支付宝或者银联二维码轻松支付；<br />
                铝合金中框：航空级高强度铝合金中框，散热强，耐热强，耐磨耐腐蚀，表面烤漆工艺处理磨砂外观高端大气。<br />
            </div>,
            tese: <div>
                人机工程学设计，操作轻松：主屏5寸高清显示屏，主体5°倾斜式人机工程学设计，搭配机械键盘，看着屏幕轻松精准输入；<br />
                键盘可更换，可编程定义：机械式数字键盘，防水防油，键盘可更换按键，可模块化编程，按需设备键盘模式；<br />
                115db大喇叭。收款听得见：内置3W大喇叭，语音实时播放到账金额，嘈杂食堂也能清晰听见每一笔收款；<br />
                整机四重防护，防护等级IP66：防油污、防尘防水、防火、防虫；<br />
                内置锂电池，停电也不怕：内置备用可充电锂电池，停电后仍可正常使用，续航时间长，方便高效。
            </div>,
        },
    },
    {
        title: '彩色智能双屏消费机',
        to: '/product/11',
        img: '/img/img_new_xiaofeiji4.png',
        scene: '本产品广泛应用于各学校食堂/超市',
        version: 'TOP-CS103',
        detail: {
            scene: '本产品广泛应用于各学校食堂/超市',
            intro: <div>
                智能台式消费机，无惧食堂苛刻使用环境；适应各种环境、安装简单，快速落地；<br />
                支持多种通讯，以太网通讯、wifi，4G全网通，通讯方式稳定；扫码支付；<br />
                兼容NFC功能，支持多种卡片；<br />
                自带机械键盘；双屏显示，商家和客户可清楚看见消费信息，避免出错。
            </div>,
            tese: <div>
                内置大功率喇叭，嘈杂环境也能清晰听见语音播报；<br />
                整机采用特殊设计工艺，防油、防水、防虫、使用寿命长；<br />
                外观简洁，操作方便，功能齐全；<br />
                外设接口丰富，可外接打印机、键盘扫码盒字等。
            </div>,
        },
    },
];

//智能门锁
export const LOCK_PRODUCTS_TEMPORARY = [
    {
        titless: '智能锁',
        imgs: '/img/img_new_zhinengsuo.png',
        title: '智能锁',
        img: '/img/img_new_zhinengsuo.png',
        scene: '本产品广泛应用于各学校公寓等门锁场景',
        to: '/product/8',
        version: 'TOP-LK202',
        detail: {
            scene: '本产品广泛应用于各学校公寓等门锁场景',
            intro: <div>
                为积极参与标准化公寓建设，宿舍的智能门锁可以实现公寓科学化的管理，提供优质的生活环境，优化宿舍资源配置，为科技创安工作做有力保障。因此对公寓提供智能门锁设施的改造迫在眉睫。<br />
                顶联物联智能蓝牙锁TOP-LK202，专为宿舍公寓设计，为用户提供蓝牙开锁、扫码开锁、刷卡开锁、手机超卡开锁等应用，实现人、锁、房间可视化管理和实时在线管控，实现宿舍管理的降本增效，系统化管理，提升安全管理水平。
            </div>,
            tese: <div>
                开锁方式：超卡开锁、扫码开锁、小程序开锁、钥匙开锁；<br />
                感应距离：蓝牙&lt;5米，IC卡0-2CM；<br />
                供电方式：采用4节5号碱性电池供电，可用6个月以上；<br />
                电池寿命：每天开锁20次，可使用12至15个月；<br />
                应急措施：可支持外部USB供电或钥匙应急开锁；<br />
                信息保护：断电信息不会丢失，联网自动上传；<br />
                管理方式：配合宿舍管理系统，实现门锁管理、权限下发、宿舍分配等。
            </div>,
            teses: [
                {
                    icon: '/img/lock_way.png',
                    content: '超卡开锁、扫码开锁、小程序开锁、钥匙开锁',
                },
                {
                    icon: '/img/lock_distance.png',
                    content: '蓝牙<5米，IC卡0-2CM',
                },
                {
                    icon: '/img/lock_power_supply.png',
                    content: '采用4节5号碱性电池供电，可用6个月以上',
                },
                {
                    icon: '/img/lock_cell.png',
                    content: '每天开锁20次，可使用12至15个月',
                },
                {
                    icon: '/img/lock_emergency_measure.png',
                    content: '可支持外部USB供电或钥匙应急开锁',
                },
                {
                    icon: '/img/lock_message_protection.png',
                    content: '断电信息不会丢失，联网自动上传',
                },
            ]
        },
    },
    {
        title: '智能门',
        img: '/img/img_new_zhinengmen.png',
        to: '/product/9',
        scene: '本产品广泛应用于各学校公寓等门锁场景',
        version: 'TOP-DR101',
        detail: {
            scene: '本产品广泛应用于各学校公寓等门锁场景',
            intro: <div>
                在高等院校后勤管理不断深化，学校招生人数逐年增加，学生安全意识和安全管理亟待提高。目前大多学校宿舍门相对老旧，且不够安全牢固，容易出现财务安全隐患和消防安全隐患。因此对公寓提供智能门设施的改造迫在眉睫。<br />
                顶联校园智能门TOP-DR101，专为宿舍公寓设计，为用户提供蓝牙开门、扫码开门、刷卡开门、手机超卡开门等应用，实现人、门、房间可视化管理和实时在线管控，实现宿舍管理的降本增效，系统化管理，提升安全管理水平。
            </div>,
            tese: <div>
                开锁方式：超卡开锁、扫码开锁、钥匙开锁；<br />
                感应距离：蓝牙&lt;5米，IC卡0-2CM；<br />
                通讯方式：蓝牙通讯；<br />
                供电方式：采用4节5号碱性电池供电，可用6个月以上；<br />
                电池寿命：每天开锁20次，可使用12至15个月；<br />
                应急措施：可支持外部USB供电或钥匙应急开锁；<br />
                信息保护：断电信息不会丢失，联网自动上传；<br />
                管理方式：配合宿舍管理系统，实现门锁管理、权限下发、宿舍分配等；<br />
                门材质：高强度钢制门，转印木纹。
            </div>,
        },
    },
];

//智能通道
export const CHANNEL_PRODUCTS_TEMPORARY = [
    {
        title: '智能翼闸',
        img: '/img/zhinengyizha.png',
        to: '/product/10',
        scene: '本产品广泛应用于各学校宿舍、图书馆、校门口等',
        version: 'TOP-AG111',
        detail: {
            scene: '本产品广泛应用于各学校宿舍、图书馆、校门口等',
            intro: <div>
                目前大多校园管理比较松散，如何加强校门看守和学生宿舍出入人员的有效管理势在必行。目前采取的是来访、出入登记的方式多为人工登记或开放式管理，管理起来非常不方便。<br />
                顶联智能翼闸TOP-AG111，专为宿舍公寓楼设计，为用户提供扫码/反扫手机开闸、手机超卡开闸、远程开闸等应用，实现人员进出可视化管理和实时在线管控，实现宿舍管理的降本增效，系统化管理，提升安全管理水平。
            </div>,
            tese: <div>
                应用场景：宿舍、图书馆、校门口等；<br />
                开闸方式：扫码/反扫手机开闸、手机超卡开闸、远程开闸；<br />
                读卡器：内置IC卡+二维码读头，可实现IC卡读卡和反扫手机二维码；<br />
                感应距离：蓝牙&lt;5米，IC卡0-2CM；<br />
                消防联动功能：红外3对；<br />
                驱动电机：24V直流有刷电机，正常使用寿命300万次以上；<br />
                通行方向：单向或双向（可设置）；<br />
                通行速度：40人/分钟（常开）；<br />
                机箱材料：国产标准304号不锈钢，机箱厚度1.2mm；<br />
                工作电压：AC220±10%V/50HZ 100W；<br />
                通讯接口：RJ45以太网口，可扩展WIFI或4G网络。
            </div>,
        },
    },
    {
        title: '智能摆闸',
        img: '/img/zhinengbaizha.png',
        to: '/product/10',
        scene: '本产品广泛应用于各学校宿舍、图书馆、校门口等',
        version: 'TOP-AG112/122',
        detail: {
            scene: '本产品广泛应用于各学校宿舍、图书馆、校门口等',
            intro: <div>
                目前大多校园管理比较松散，如何加强校门看守和学生宿舍出入人员的有效管理势在必行。目前采取的是来访、出入登记的方式多为人工登记或开放式管理，管理起来非常不方便。<br />
                顶联智能翼闸TOP-AG111/121，专为宿舍公寓楼设计，为用户提供扫码/反扫手机开闸、手机超卡开闸、远程开闸等应用，实现人员进出可视化管理和实时在线管控，实现宿舍管理的降本增效，系统化管理，提升安全管理水平。
            </div>,
            tese: <div>
                应用场景：宿舍、图书馆、校门口等；<br />
                开闸方式: 反扫手机二维码开闸、手机超卡开闸、远程开闸；<br />
                读卡器：内置IC卡+二维码读头，可实现IC卡读卡和反扫手机二维码；<br />
                感应距离：蓝牙&lt;5米，IC卡0-2CM；<br />
                消防联动功能：红外6对；<br />
                驱动电机：24V直流无刷电机，正常使用寿命300万次以上；<br />
                通行方向：单向或双向（可设置）；<br />
                通行速度：30-40人/分钟（常开）；<br />
                机箱材料：国产标准304号不锈钢，机箱厚度1.2mm；<br />
                工作电压：AC220±10%V/50HZ 100W；<br />
                通讯接口：RJ45以太网口，可扩展WIFI或4G网络。
            </div>,
        },
    },
    {
        title: '智能门禁读卡器',
        img: '/img/zhinengmenjindukaqi.png',
        to: '/product/11',
        scene: '适用宿舍、图书馆、校内各出入门禁等场景',
        version: 'TOP-AC101',
        detail: {
            scene: '适用宿舍、图书馆、校内各出入门禁等场景',
            intro: <div>
                开门方式：扫码/反扫手机开门、手机超卡开门、CPU卡开门；<br />
                读卡类型：内置13.56MHz IC卡读头，支持手机超卡、CPU卡读卡；<br />
                数据加密：支持手机超卡、CPU卡加密读卡，防复制，防破坏；<br />
                二维码识别：可实现手机二维码识别开门，最远可达15CM；<br />
                蓝牙通讯：支持蓝牙4.0以上，蓝牙识别距离&lt;5米；<br />
                通讯接口：RJ45以太网口，可扩展WIFI或4G网络。
            </div>,
            tese: <div>
                目前大多校园管理比较松散，随着人们对学校安防的重视，门禁系统成为学校安防必不可少的一部分；<br />
                顶联智能门禁读卡器转为学校宿舍公寓、教室、办公室等场景设计，可嵌入各种通道闸设备中。门禁读头内置IC卡读头和二维码读头，为用户提供扫码/反扫手机开门、手机超卡开门、远程开闸等应门，实现人员进出可视化管理和实时在线管控，实现宿舍管理的降本增效，系统化管理，提升安全管理水平。
            </div>,
        },
    },
    {
        title: '智能人脸门禁机',
        titless: '智能人脸门禁机',
        imgs: '/img/pro_s_face_menjin.png',
        img: '/img/img_new_zhinengrenlianmenjinji.png',
        to: '/product/12',
        scene: '本产品广泛应用于各学校适用宿舍、图书馆、校门口出入门禁等',
        version: 'TOP-FC101',
        detail: {
            scene: '本产品广泛应用于各学校适用宿舍、图书馆、校门口出入门禁等',
            intro: <div>
                采用电容式多点触控触摸屏，支持本地快速设置和人脸名单库录入，方便快捷；<br />
                设备可进行WEB页面管理，支持登录后查询、设置、管理设备参数；<br />
                设备可存储用户数据，支持在断网模式下，单机运行功能；<br />
                安装方式：立柱安装、挂墙安装。
            </div>,
            tese: <div>
                独有的人脸识别算法精准识别人脸，人脸识别时间&lt;500ms；<br />
                口罩识别准确率85%，满足常规口罩检测要求，支持口罩佩戴提示；<br />
                支持语音播报；支持语音对讲；<br />
                支持5万人脸底库及10万条人脸识别记录；<br />
                支持密码开门；<br />
                接口协议丰富：支持 Windows/Linux等多种平台下的SDK及HTTP、MQTT协议。
            </div>,
            teses: [
                {
                    icon: '/img/face_face.png',
                    content: '独有的人脸识别算法精准识别人脸，人脸识别时间<500ms',
                },
                {
                    icon: '/img/face_gauze_mask.png',
                    content: '口罩识别准确率85%，满足常规口罩检测要求，支持口罩佩戴提示',
                },
                {
                    icon: '/img/face_voice.png',
                    content: '支持语音播报；支持语音对讲',
                },
                {
                    icon: '/img/face_password.png',
                    content: '支持密码开门',
                },
                {
                    icon: '/img/face_agreement.png',
                    content: '支持Windows/Linux等多种平台下的SDK及HTTP、MQTT协议',
                },
                {
                    icon: '/img/face_record.png',
                    content: '支持5万人脸底库及10万条人脸识别记录',
                },
            ]
        },
    },
    {
        title: '智能人脸识别门禁机（立柱测温版）',
        img: '/img/zhinengrenlianshibiemenjinji.png',
        to: '/product/13',
        scene: '广泛应用于各学校、公寓、出租房等通道闸、通道门、防疫测温场所',
        version: 'TOP-FC111',
        detail: {
            scene: '广泛应用于各学校、公寓、出租房等通道闸、通道门、防疫测温场所',
            intro: <div>
                体温检测：采用32X32德国海曼测温芯片，测量精度可达0.1℃，检测误差≦±0.3℃；<br />
                语音告警：支持未戴口罩检测告警、体温异常检测告警；<br />
                人像识别+测温只需0.5秒，无感测温通行；<br />
                测温距离1m以内，最佳测温距离70cm；<br />
                适应温差范围10℃~30℃，不防水，适用于室内场景；<br />
                8寸全视角非触摸屏，1280*800竖屏，全贴合设计；<br />
                屏幕亮度350~450尼特，阳光下屏幕清晰可见；<br />
                采用深度学习算法模型，面部无遮挡人像识别率>99.9%，未戴口罩检出率>99.5%，戴口罩条件下人像识别率>95%；<br />
                支持双目活体检测，可抵御照片、视频等方式的攻击；<br />
                内置高性能AI处理芯片，支持本地离线识别；<br />
                支持5万含图人像库容量；<br />
                支持外接IC读卡器、身份证读卡器，支持刷脸、刷卡、人卡、人证等多种核验模式；<br />
                内置8GB EMMC大容量存储，稳定可靠，支持5万条小图记录，100万无图记录；<br />
                支持不保存人像图片，只保留特征值，有效保护用户隐私；<br />
                可配套自研管理平台，实现防疫测温管理、通行管理、考勤管理等多种应用；<br />
                提供 HTTP 对接方式，支持接入第三方管理平台。
            </div>,
            tese: <div>
                为积极参与标准化公寓建设，实现公寓科学化的管理，提供公寓防疫管理，为学校科技创安工作做有力保障。<br />
                顶联8 寸测温闸机终端TOP-FC202是一款高性能、高可靠性的人像测温闸机产品，可对人员身份、人员体温和人员是否配戴口罩进行精确识别和检测，具备识别准确率高、测温准确率高、测温速度快等特点。
                通过接入管理平台，可实现防疫测温管控，广泛适用于企业园区、高校、社区、医疗等场景。
            </div>,
        },
    },
    {
        title: '双目人脸识别门禁机（人证合一版）',
        img: '/img/shuangmurenlianshibiemenjinji.png',
        to: '/product/13',
        scene: '广泛应用于各学校、公寓、出租房等通道闸、通道门等场所',
        version: 'TOP-FC121',
        detail: {
            scene: '广泛应用于各学校、公寓、出租房等通道闸、通道门等场所',
            intro: <div>
                采用业界领先的可见光+近红外双目摄像头设计，以深度学习人脸识别技术为依托，提供高性能、高可靠性的人脸识别身份出入权限管理并支持人脸识别验证结果播报以及自动检测口罩是否佩戴提醒功能。其次，该产品特配备专业ISP图像处理，轻松适应逆光、强光还是弱光等各种复杂光源。<br />
                解决传统人工值守管理人员出入人工成本大、效率低难等弊端；全天候帮助管理者完成区域门控权限管理与人员考勤管理，极大减少了门禁安全系统中现存及潜在的技术漏洞、隐患和风险。<br />
                采用公安部授权的二代身份证阅读器，安全、精准的完成人证核验比对。
            </div>,
            tese: <div>
                人脸识别：<br />
                支持双目活体检测技术，有效防范照片、视频、模型等攻击行为；99.5%的识别率；1：N人脸识别时间&lt;500ms；<br />
                支持 3个人脸库的管理，支持最多50000 张人脸库；支持多个布防时间段设置；支持强光、逆光、星光等各种恶劣环节下能有效识别。<br />
                人脸识别+口罩检测：<br />
                人脸+口罩识别准确率85%，满足常规口罩检测要求，支持口罩佩戴提示；<br />
                语音播报：支持人脸识别验证结果播报、异常播报、刷卡提示播报；<br />
                支持在线联网，搭配管理云平台使用，实时推送通行记录；<br />
                接口协议丰富，支持Windows/Linux等多种平台下的SDK及HTTP协议；<br />
                支持二代身份证人证比对（采用公安部授权的二代身份证阅读器）；<br />
                支持10万 条记录可存可导出，方便回溯。
            </div>,
        },
    },
    {
        title: '人脸识别服务器',
        img: "/img/img_new_renlianshibiefuwuqi.png",
        to: '/product/14',
        scene: '本产品广泛应用于各学校教室、宿舍、图书馆、校门口出入门禁等系统',
        version: 'TOP-FS141',
        detail: {
            scene: '本产品广泛应用于各学校教室、宿舍、图书馆、校门口出入门禁等系统',
            intro: <div>
                支持人脸抓拍、人脸布控、人脸识别等多种智能检测接入和联动；<br />
                支持烟火检测、消防车通道占用、通道堵塞、物品遗失、区域入侵等多种智能检测扩展接入和联动；<br />
                设备集成音视频解码、数据传输、存储、智能算法等多种技术为一体。其外接普通网络像机输入视频，对图像中出现的对象进行智能分析，输出异常告警信号，实现异常行为告警，该设备部署方便，操作简易，环境适应性强；<br />
                自有先进深度学习算法，抓拍识别速度快、准确度高。工业级标准设计，外观精致，坚固可靠，适用于各类室内外应用环境。兼容性好，能够接入主流厂商普通网络摄像机；接口丰富，对接灵活，方便第三方系统集成。

            </div>,
            tese: <div>
                人脸识别与多种行为分析算法可同时运行，场景功能可灵活切换；<br />
                前端普通相机，最高支持8路人脸识别，10万人脸库；<br />
                接入前端普通相机，最高支持8路视频流行为分析；<br />
                历史抓拍、报警数据一键查询；<br />
                支持告警信息上报，并截取告警片段留存至本地；<br />
                接口：RJ45 自适应以太网口，双千兆网卡，支持双网络IP设定内外网隔离等应用。
            </div>,
        },
    },
    {
        title: '人脸布控服务器',
        img: "/img/renlianbukongfuwuqi.png",
        to: '/product/14',
        scene: '本产品广泛应用于各学校教室、宿舍、图书馆、校门口出入门禁等系统',
        version: 'TOP-FS142',
        detail: {
            scene: '本产品广泛应用于各学校教室、宿舍、图书馆、校门口出入门禁等系统',
            intro: <div>
                人脸布控服务器采用高性能的 Hi3559AV100
                芯片,内嵌深度学习算法,集摄像机接入、管理、控制、智能分析于一体。搭配人脸抓拍机，对图片中人脸进行智能建模和分析，通过人脸名单库比对实现报警功能，通过以图搜图、以图搜录像实现目标人员的快速查找。
            </div>,
            tese: <div>
                校园周界布控系统采用嵌入式设计，内嵌深度学习算法，集摄像机接入、存储、控制、智能分析于一体，实现精确的人脸分析，包括人脸图片比对、检索等；<br />
                可接入16路人脸抓拍摄像机或16路常规摄像机（8路视频流分析）；<br />
                支持H.265+、H.265、H.264编码前端自适应接入；<br />
                本地支持最大导入黑、白、vip共10万张人脸库；<br />
                支持实时人脸比对、属性提取、名单报警、人脸保存，也拥有以图搜图、以图搜录像等特色功能；<br />
                支持Onvif协议，可接驳第三方设备做视频流分析；<br />
                支持报警信息推送功能；<br />
                支持 DHCP, FTP, DNS, DDNS, NTP, UPNP, EMAIL,IP 搜索,报警中心等；<br />
                支持 HTTP、MQTT 上传比对结果；<br />
                完善的保护电路，独有的三重看门狗功能；<br />
                支持4个SATA接口，最大支持8TB硬盘，可用于录像和备份。
            </div>,
        },
    },
];

//停车管理
export const PARK_PRODUCTS_TEMPORARY = [
    {
        title: '车牌识别服务器',
        img: '/img/img_new_chepaishibiefuwuqi.png',
        to: '/product/15',
        scene: '广泛应用于学校、小区、写字楼、商业广场 、企事业单位等场所的各种停车场出入管理',
        version: 'TOP-LS101',
        detail: {
            scene: '广泛应用于学校、小区、写字楼、商业广场、企事业单位等场所的各种停车场出入管理',
            intro: <div>
                外接摄像机、显示屏、道闸实现车牌识别出入控制，车行数据通讯；<br />
                一套服务器可接一进一出两路摄像机；<br />
                4G全网通通信，远程实时监控；<br />
                支持有线网络接入；<br />
                远程下载车牌，无距离限制；<br />
                安装便捷，只需电源，解决多口布线困难问题；<br />
                DC12V电压设计；<br />
                标准模块化结构设计，安装更加方便；<br />
                兼容性强，可兼容市面上大部分摄像机和停车管理系统。
            </div>,
            tese: <div>
                随着我国城市化的飞速发展，以及人们对社区安防的重视，停车场系统成为智慧社区必不可少的一部分。目前市场停车场系统多为有线门禁，一旦出现问题，安装维护麻烦，用户使用极不方便；<br />
                飞雅无线车牌识别服务器基于4G通信网络，打破有线通讯方式，可以实现车牌识别、道闸控制、远程控制、扫码停车、一位多车等功能。本产品无需布线，安装快捷、使用方便，可应用于各种停车场月租车管理和车场无人值守收费等场合。
            </div>,
        },
    },
    {
        title: '车牌识别一体机1',
        img: '/img/chepaishibieyitiji1.png',
        to: '/product/16',
        scene: '广泛应用于学校、小区、写字楼、商业广场、企事业单位等场所的各种停车场出入管理',
        version: 'TOP-LR101',
        detail: {
            scene: '广泛应用于学校、小区、写字楼、商业广场 、企事业单位等场所的各种停车场出入管理',
            intro: <div>
                车牌识别系统针对这些基础的算法有了更进一步，深层次的运用。解决了在复杂背景的图象中如何准确而迅速地定位分割牌照区域；<br />
                一体化摄像机专指可自动聚焦、镜头内建的摄像机。与传统摄像机相比，一体化摄像机体积小巧、美观，安装、使用方便，监控范围广、性价比高等优点。
            </div>,
            tese: <div>
                车牌识别一体机是在一体化摄像机上加入车牌识别的功能，车牌识别一体摄像机针对停车场行业，推出的基于嵌入式的智能高清车牌识别一体机产品，独家集车牌识别、摄像、前端储存、补光等一体，基于车牌自动曝光控制算法，成像优异；<br />
                具有极佳性能、多功能、高适应性、强稳定性等特点；<br />
                支持车牌识别、LED显示、夜间抓拍、道闸控制；<br />
                输出信息：车辆特征图像、车牌图像、牌照号码、颜色、类型、通过时间等。
            </div>,
        },
    },
    {
        title: '车牌识别一体机2',
        img: '/img/chepaishibieyitiji2.png',
        to: '/product/16',
        scene: '广泛应用于学校、小区、写字楼、商业广场、企事业单位等场所的各种停车场出入管理',
        version: 'TOP-LR102',
        detail: {
            scene: '广泛应用于学校、小区、写字楼、商业广场、企事业单位等场所的各种停车场出入管理',
            intro: <div>
                车牌识别系统针对这些基础的算法有了更进一步，深层次的运用。解决了在复杂背景的图象中如何准确而迅速地定位分割牌照区域；<br />
                1.支持微信支付；<br />
                2.支持电脑多画面，一台电脑最多可以管理十几个出入口，给不好放电脑的地方提供方便，节省开支，高度集成化管理；<br />
                3.支持手机锁车功能，车主停好车，发短信至总机，自动锁车，此车辆无法通过车牌识别系统，车主要出车场时，再发条短信解锁，提高车场的安全性；<br />
                4.无牌车辆放行时，可自动生成车牌号码，无需填写，提高工作效率；<br />
                5.几十种收费标准可以选择，支持车牌储值卡功能；<br />
                6.支持同组车功能，一个车主可以有多辆车；<br />
                7.软件可定制，可根据客户要做的要求，定制软件，完成个性化的功能；<br />
                8.软件可设置默认省区，输入车牌时，更加有想效率；<br />
                9.丰富的报表查询功能，并可以导出excel；<br />
                10.支持批量导入车牌，提高效率，减少工作量。
            </div>,
            tese: <div>
                车牌识别特征：号码、颜色、类型、宽度；<br />
                车牌防伪：支持异常车牌（手机拍照、打印）告警；<br />
                无牌车触发：支持无牌车视频触发；<br />
                车标识别：支持主流车标识别；<br />
                车牌白名单：支持精准、智能模糊匹配白名单车牌规则；
                智能校准：支持精准或以通配符的方式，智能校准车牌号及车牌类型及颜色；无人值守：与通道机配合使用，形成无人值守系统；<br />
                智能补光：根据画面亮度，自动控制灯光，彻底解决逆光、强光等特殊环境；<br />
                文字显示：四个汉字，四排四色显示，车牌号码，停车时间，收费金额等，根据不同场景设置不同内容；<br />
                文字显示模式：向下移动、向上移动、水平移动、固定显示；<br />
                车位管理：一位多车；多位多车等系统管理；<br />
                自定义语音播报：自带语音播报功能；根据不同场景播报不同内容，可选配脱机收费功能。
            </div>,
        },
    }
];

//校园网络
export const SCHOOLNET_LIFE_PRODUCTS_Temporary = [
    {
        title: '宿舍AP',
        img: '/img/img_new_susheAP.png',
        scene: '广泛应用于高教宿舍、酒店、密集办公等场景',
        to: '/product/8',
        detail: {
            scene: '广泛应用于高教宿舍、酒店、密集办公等场景',
            intro: <div>
                智分型无线接入点，采用802.11ax协议标准，将一台AP通过延伸出48个支持光纤接入的Radio模块，支持48个房间的无线接入性能，每个房间最高可享有1.775G的带宽资源，满足宿舍环境等高性能接入的需求。<br />
                除此之外本产品充分考虑的无线网络安全、射频控制、移动访问、服务质量保证、无缝漫游等重要因素，完成无线用户的数据转发、安全和访问控制。
            </div>,
            tese: <div>
                <b>极简以太全光方案架构</b>：光纤入室、带宽独享；简化施工：光纤实现无拘无束的部署，相比传统的以太网线路，光纤线路具有通讯距离长、体积小、寿命长的优点；<br />
                开放兼容、智能运维：采用光纤标准线路组网。既可利旧原自有设备部署，也可接入市面其他厂商光纤线路和设备，充分保护用户利益和原有投资；<br />
                弹性扩张、业务无忧：带宽升级中只需要更换AP主机或智分单元，即可快速实现房间的带宽升级；<br />
                智能供电、安全无忧：每路供电单元最大输出功率172W，能够在200米的范围内，承载12个智分+微AP，4路电源累计为48个智分+微AP。解决了因为本地供电带来的部门协调、用电安全、电费归属的问题，大幅提升管理运维的简便性；<br />
                <b>多级分布式系统架构</b>：入室部署的微AP射频单元采用独立CPU进行数据处理和转发，独立射频芯片进行多用户空口调度。主AP+微AP的多级分布式架构设计给极简以太全光智分+解决方案带来了更高性能；<br />
                <b>灵活完备的安全策略</b>：用户数据加密安全、射频安全、多种易用性认证方式、DHCP安全、防ARP病毒攻击、主动防御网络中各类DoS攻击、管理信息安全，基于源IP地址控制的Telnet访问控制，更加精细的提供了设备管理控制，保证只有管理员配置的IP地址才能登陆AP，增强了设备网管的安全性。
            </div>,
        },
    },
    {
        title: '放置AP',
        img: '/img/img_new_fangzhiAP.png',
        to: '/product/9',
        scene: '广泛应用于高教教学楼、政府、普教、金融、商业等室内场景',
        detail: {
            scene: '广泛应用于高教教学楼、政府、普教、金融、商业等室内场景',
            intro: <div>
                双路双频通用级放装型无线接入点，支持802.11ax、802.11ac Wave2、802.11ac
                Wave1和802.11n等协议。采用硬件独立的双射频设计，整机最大可提供2.4Gbps的接入速率；当工作在2.4G +
                5G模式时整机最大提供1.775Gbps的接入速率，高速无线让性能不再成为瓶颈。<br />
                该产品充分考虑了无线网络安全、射频控制、移动访问、服务质量保证、无缝漫游、物联网模块拓展等重要因素，配合无线控制器和RG-WIS产品完成无线用户数据转发、安全、访问控制和物联网应用拓展。
            </div>,
            tese: <div>
                高速无线，省电更可靠：高密部署与接入环境下，单用户的平均速率最高可提升至802.11ac的4倍；<br />
                BSS Color空间复用：空间复用功能，将网络中不同的WLAN的基本服务级通过不同的着色进行标识，该技术可以实现高密场景环境下的信道复用，更大缓解实际网络部署中同频干扰的影响；<br />
                绿色环保，单位性能耗电降低：大量的节能新技术应用，包括单天线待机技术、动态MIMO省电技术、增强级自动省电传送技术以及逐包功率控制技术等，结合高性能的电源设计，提供高速无线接入的同时，轻松节省电能；<br />
                智能识别功能：支持终端智能识别，能够识别出iOS、Android等智能移动终端和PC机；<br />
                全面安全防护更易用：支持Web、802.1x、MAC地址、本地认证等多种用户准入认证方式供客户选择。
            </div>,
        },
    },
    {
        title: '室外AP',
        img: '/img/img_new_shiwaiAP.png',
        to: '/product/10',
        scene: '广泛应用于高教、无线城市、能源、商贸广场等室外场景',
        detail: {
            scene: '广泛应用于高教、无线城市、能源、商贸广场等室外场景',
            intro: <div>
                支持802.11ax、802.11ac Wave2、802.11ac Wave1和802.11n等协议。采用硬件独立的双射频设计，整机最大可提供2.4Gbps的接入速率；当工作在2.4G
                +
                5G模式时整机最大提供1.775Gbps的接入速率，高速无线让性能不再成为瓶颈。<br />
                该产品充分考虑了无线网络安全、射频控制、移动访问、服务质量保证、无缝漫游、物联网模块拓展等重要因素，配合无线控制器和WIS产品完成无线用户数据转发、安全、访问控制和物联网应用拓展。
            </div>,
            tese: <div>
                多业务端口设计：整机最高可支持2G的有线接入速率；一个自适应以太网电口，提供最高1G的高速有线接入；一个自适应以太网光口，提供最高1G的高速有线接入；实现无线与有线之间的高速传输转换；<br />
                高速无线，省电更可靠：双路双频设计，采用新一代Wi-Fi无线标准802.11ax协议；双射频同时开启，高达2.4Gbps的高速无线速率，带来高速完满体验；<br />
                BSS Color空间复用：空间复用功能，将网络中不同的WLAN的基本服务级通过不同的着色进行标识，该技术可以实现高密场景环境下的信道复用，更大缓解实际网络部署中同频干扰的影响；<br />
                绿色环保，单位性能耗电降低：大量的节能新技术应用，包括单天线待机技术、动态MIMO省电技术、增强级自动省电传送技术以及逐包功率控制技术等，结合高性能的电源设计，提供高速无线接入的同时，轻松节省电能；<br />
                智能识别功能：支持终端智能识别，能够识别出iOS、Android等智能移动终端和PC机；<br />
                全面安全防护更易用：支持Web、802.1x、MAC地址、本地认证等多种用户准入认证方式供客户选择。
            </div>,
        },
    },
    {
        title: '智能物联网关',
        img: '/img/zhinengwulianwangguan.png',
        to: '/product/7',
        scene: '广泛应用于宿舍、酒店、医院病房和小型办公室等',
        detail: {
            scene: '广泛应用于宿舍、酒店、医院病房和小型办公室等',
            intro: <div>
                新一代Wi-Fi6商用高性能入墙式面板无线接入点；<br />
                内置蓝牙物联网关，以将其覆盖范围内的低功耗蓝牙设备连接到LAN或Internet，以便用户通过 AC 实现远程控制和管理；<br />
                支持Bluetooth LE 4.0/4.1/4.2，兼容 5.0，对其部署在室内的蓝牙门锁、蓝牙水控器等设备实现无缝覆盖；<br />
                基于最新的Wi-Fi 6标准，支持2x2 MU-MIMO技术、OFDMA空间复用技术和1024QAM调制解调算法；<br />
                整机最高速率可达1.775Gbps；<br />
                可在不破坏墙面装修的情况下安装在任意86面板盒上，极大的减少了部署成本；<br />
                采用千兆以太网口上行链路，保证无线高速传输；<br />
                支持PoE远程供电，可根据客户现场供电环境进行灵活选择。
            </div>,
            tese: <div>
                灵活网络部署：部署方便、千兆上行链路；支持POE供电；支持网线、电话双接入；统一集中管理；<br />
                极速无线上网体验：Wi-Fi6高速接入；二三层无缝漫游；智能射频，全面降低无线干扰；内置蓝牙，支持物联网场景扩展；<br />
                全面的安全防护：多种易用、安全的认证方式；全面的无线安全防护与可靠系统；射频定时关闭，保护网络安全，绿色环保，本地转发应用识别等。
            </div>,
        },
    },
];


//智能教室
export const CLASSROOM_PRODUCTS_TEMPORARY = [
    {
        title: '智能交互平板',
        img: '/img/img_new_zhinengjiaohupingban.png',
        scene: '广泛应用于学校教室、会议室等场景',
        to: '/product/8',
        detail: {
            scene: '广泛应用于学校教室、会议室等场景',
            intro: <div>
                智能交互平板，高清显示，搭载笔式人机交互技术以及多媒体信息处理等技术，内置鸿合π交互教学软件，可实现多人触控、多人书写、多屏互动等丰富交互教学形式，标配一体化4K摄像头，轻松实现AI课堂、专递课堂，让课堂更生动、教学更高效。
            </div>,
            tese: <div>
                高清分辨率：超细腻显示画面细节，超广可视角度，坐在不同位置也能清晰看到教学内容；<br />
                高色域：图片呈现真实艳丽，赏心悦目，生动有趣，为课堂填充更多色彩；<br />
                去蓝光技术：可降低超过90%超短波蓝光对人眼的伤害；“护眼+“前置按键，智能调节色温贴心守护学生视力；<br />
                笔式人机交互技术：提高对复杂笔迹的识别度；老师随时随地畅快书写，灵活批注，表达不受限；<br />
                2.1声道50W音箱：音质澎湃震撼；清晰流畅、环绕感极强，带给学生沉浸式的视听体验；<br />
                新增全功能Type-C接口：音、视频及数据传输能力更强；高速传输稳定可靠，全面提高教师教学效率。
            </div>,
        },
    },
    {
        title: '智能窗帘',
        img: '/img/img_new_zhinengchuanglian.png',
        to: '/product/9',
        scene: '广泛应用于学校教室、会议室等场景',
        detail: {
            scene: '广泛应用于学校教室、会议室等场景',
            intro: <div>
                功能：自我反应、调节、控制；<br />
                根据室内环境状况自动调光线强度、空气湿度、平衡室温等，有智能光控、智能雨控、智能风控三大突出的特点。
            </div>,
            tese: <div>
                透光度：可调节的透光度去适应不同天气下的室内环境；<br />
                隐秘度：特定的时间内，可通过调节，最大化保障室内隐私、室内自由度；<br />
                隔音隔热：有些过大的太阳或是过大的声响，易影响学生思绪。智能窗帘科学的提升隔音隔热效果，为教学质量提供保障；<br />
                防紫外线：健康是每个学校的关注重心，但轻微的健康威胁却容易被忽视。过多紫外线照射易引起身体不适，防紫外线设计，随心享受；<br />
                保温环保：在当前环保时尚流行下，智能窗帘氦氪的保温功能，有效解决个人热能资源浪费问题。践行节能低碳，共建绿色校园。
            </div>,
        },
    },
];

//移动应用
export const APP = [
    {
        title: '菁彩校园APP',
        img: '/img/jingcaixiaoyuanapp.png',
        scene: '',
        to: '/product/8',
        detail: {
            scene: '',
            intro: <div>
                菁彩校园APP，是一款面向校园师生，提供校园一站式服务的移动数字化平台，融合信息、通讯、办公、管理、协作、生活、学习等领域，构建高效、适配、体验、开放、整合的移动校园门户，帮助用户随时随地、高效、高体验的完成工作目标。            </div>,
            tese: <div>
                校内统一登录：实现学校账号或个人账号的统一登录，不同身份实现不同可见范围，构建智能、高效、便捷的业务连接。<br />
                业务能力集合：采用原生+小程序的架构开发，集合一校通、生活应用、教务学工等各类服务，融合门户及多业务一体，一站式使用各业务应用。<br />
                通用能力支撑：多种基础能力套件，支持扫一扫、消息、待办、全局搜索、日历、帮助、卡片、意见反馈、个人中心等功能，并与业务应用深度融合，满足学校日常应用。
            </div>,
        },
    },
];

export const sceneData = [
    {
        title: '洗浴',
        desc: '助力智慧校园建设,开创校园洗浴智能时代。',
        content:
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;校园传统洗浴多采用集中大澡堂的模式，距离宿舍区较远，学生洗浴不方便，特别是北方冬季寒冷，需要集中大量供暖和供热，致使高校资金压力大。为解决学生洗浴以及高校的痛点，顶联改变传统洗浴模式，将洗浴引入到宿舍楼内，采用新型小集中洗浴、洗浴入户的模式，解决了学生洗浴距离远、时间窗口集中、无隐私的的困境。通过全新的智能洗浴系统，实现了学生线上实时查看设备使用情况、一键预约；通过BOT的模式，由顶联及运营商联合投建，负责合同期内的运营、管理、维护，降低了校方的资金压力和管理成本，实现了“零”成本升级建设校园洗浴服务。</div>,
        img: '/img/xiyu_bg.png',
        icon: '/img/xiyu_icon.png',
        to: '/product/0',
    }, {
        title: '直饮水',
        desc: '升级校园饮水品质,享受高品质生活。',
        icon: '/img/water_icon.png',
        content:
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;以校园集中式开水房为代表的传统校园饮水方案，时常出现接水不便、用水高峰供应不足的现象，且受建设方案和运维质量的限制，水质安全难以保障。为守护师生饮水健康，减轻高校的运维压力和资金负担，顶联推出了基于IoT技术的新一代饮用水设施，服务校园饮水场景。设备采用步时式加热技术，加热快、水量大；内置多级过滤系统，有效去除水中污染物，搭配多重消毒抑菌技术，杜绝二次污染；同时，通过物联网技术，对供水设备进行全时监控，运维管理人员可以随时通过小程序查看设备的运行情况，接收一键报修和算法识别的设备问题，有效提高了运维效率，同时保证项目的高质量运行，大大减轻了校方的管理和维护压力。</div>,
        img: '/img/water_bg.jpg',
        to: '/product/1',
    }, {
        title: '共享洗衣',
        desc: '优化洗衣体验,享受智能生活。',
        icon: '/img/xiyi_icon.png',
        content:
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;传统的大学宿舍洗衣机，常出现零钱支付不便、取衣迟滞造成设备利用率低、排队难、故障维修不及时的问题。同时给运营商频繁维修、投币支付结算造成困扰。面对以上问题，顶联推出基于物联网的共享洗衣服务，为学生提供线上预定、付款洗衣、收衣提醒等一站式服务。学生通过APP、小程序可实时查看附近洗衣机的运行状态，通过校园一卡通、支付宝、微信、小程序扫码等多种方式便捷支付。产品采用高品质电机、离合器，满足高频、高强度、连续工作的洗衣场景,大大减少了维修频次。而且，可同步配置烘干机，为学生提供更加全面的服务。</div>,
        img: '/img/xiyiji_bg.png',
        to: '/product/2',
    }, {
        title: '共享烘干机',
        desc: '实现即干即穿，引领校园晾衣革命。',
        icon: '/img/hongganji_icon.png',
        content:
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在高校与寄宿式学校中，晾衣是学生们长期面临的痛点，南向阳台有限、晾衣空间狭小、冬天日照时间短、雨季阴天潮湿等因素，都会导致衣服不易干、易返潮和滋生细菌的问题。为此，顶联从用户需求切入，为学生提供共享洗衣、共享烘干机服务。一站式洗衣、烘干加除菌，按需使用、按次付费、按需预约，让学生不出宿舍楼即可享受便捷、健康。同时，通过投资运营的模式，也为学校大大减轻了投建费用和运维管理的压力。</div>,
        img: '/img/hongganji_bg.png',
        to: '/product/3',
    }, {
        title: '共享吹风机',
        desc: '安全绿色用电,乐享便捷服务。',
        icon: '/img/chuifengji_icon.png',
        content:
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;出于美观、健康的需求，吹风机成为现代人生活中的必需品，同时由于功率大带来的用电隐患，又成为了学生宿舍中的“违禁品”。顶联从用户需求出发，在宿舍楼内洗漱间、公共浴室、校园集中浴室等校园场景中，建设了共享吹风机。学生通过APP或小程序即可启动设备，按需消费。运营商可以通过后台对所有的设备进行监控和管理，所有消费数据一目了然。而且，公司采用优质供应商产品，坚持高标准施工规范要求，并通过倒计时保护、主板过载保护、漏电开关等手段，极力保障用电安全。</div>,
        img: '/img/chuifengji_bg.png',
        to: '/product/4',
    }, {
        title: '智能空调',
        desc: '共享空调服务,共享美好生活。',
        icon: '/img/kongtiao_icon.png',
        imgStyle: { width: '25px', height: '15px' },
        content:
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;高校空调高额的购置费用和后续的管理等问题，让高校顾虑重重。为了更好地服务智慧校园建设，使学生享受更加便捷、智能、贴心、舒适、高效的智慧空调解决方案。顶联推出以租代买的模式，有效解决学校购置空调的资金问题，从而满足学生的使用需求。并结合大数据算法，分析学生的空调设置习惯，在保障舒适体验下，进行智能调整，达到省电目的。同时，学生可自行完成在线续租退租、智能操控分享、租赁套餐设置、资产账务管理、异常自动报警等系列操作，解决了租赁手续麻烦、质量服务无保障、收费标准不统一等诸多难题。</div>,
        img: '/img/kongtiao_bg.png',
        to: '/product/7',
    }, {
        title: '共享出行',
        desc: '携手校园，打造高校出行新潮流。',
        icon: '/img/chuxing_icon.png',
        content:
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;大学校园面积大，学生出行是刚需。共享出行的快速发展一定程度上改善了师生的出行条件，节约了学生的购车成本，避免了校园车辆丢失和“僵尸车”的堆积，有效解决学生在2-10公里以内的出行需求。顶联共享出行服务以为学生提供便捷、智慧的出行方式为出发点，聚焦行业伙伴和出行共同体，以更开放的姿态面向高校提供自助共享租车服务，可以实现一键扫码，快速开锁，无桩设计，随借随还；师生用车免押金、电子围栏内还车、地图找车，方便快捷；超低价格，月卡更省钱，31天畅骑不限次；与此同时，接入支付宝芝麻信用体系，助力用户智慧出行，即定即走。</div>,
        img: '/img/chuxing_bg.png',
        to: '/product/6',
    }, {
        title: '智慧食堂',
        desc: '“互联网+”智慧食堂,用餐更安全。',
        icon: '/img/shitang_icon.png',
        content:
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;传统的高校食堂，常常面临人多排队、饭卡充值不便、菜品不合口味、营养难均衡、库存难管理、剩餐严重等诸多问题。顶联智慧食堂解决方案，打造便捷的食堂订餐及就餐模式,满足食堂多样化餐饮经营模式。同时具备强大的后台管理功能，包含用户管理、消费限制、菜谱管理、卡务管理、进销存管理、数据统计、互动中心、食品安全等。学生可以通过学生可通过手机APP、小程序完成选餐、订餐、支付、充值、评价等消费环节，提升就餐便捷性。还可以通过菜品留样、明厨亮灶、农残快检、食材流转记录、供应商信息等全过程监管溯源，监管餐食的制作过程，做到有据可查，提升就餐健康体验。</div>,
        img: '/img/shitang_bg.png',
        to: '/product/7',
    }, {
        title: '智慧超市',
        desc: '打造更懂学生消费的平台。',
        icon: '/img/chaoshi_icon.png',
        content:
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;传统超市通过人工手段采集数据，完成商品采购、价格管理、门店促销等环节的决策。而互联网+背景下，尤其对于消费群体巨大的高校来说，则更加需要一个能够提供全方位、详实、准确、可靠的数据采集的系统工具来进行全面决策。顶联从师生即时性需求出发，推出了智慧超市服务，为学生提供个性化定制方案。对学生而言，刷脸即可享受智慧超市数字系统精准规划的商品推荐和优惠信息，并显示商品所在超市的位置和路线图。于商家而言，智慧超市对接线上和线下系统，将线上营销的流量导入线下实体店，节省获客成本。而且，智慧超市支持手机与产品多维交互，让学生和商家之间形成点对点精准识别和营销推荐，让学生体验到更加智能化、人性化和个性化的服务。</div>,
        img: '/img/chaoshi_bg.png',
        to: '/product/8',
    }, {
        title: '校园影院',
        desc: '丰富课余文化生活,满足精神文化需求。',
        icon: '/img/yingyuan_icon.png',
        content:
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;目前国内学校大多采用电视录像、多媒体投影的方式来观看影片，设备陈旧老化，视听效果一般，场地、屏幕太小不能组织集体观影。而大屏幕的专业影院，对学校的电影教育、远程教育、文化娱乐有极大的提升作用，大屏幕专业影院取代录像室、多媒体投影是大势所趋。顶联校园影院服务采用国际领先的NEC放映设备，满足广大学生的2D、3D观影需求，同步放映国内外最新影片。同时，影院鼓励大学生微电影创作，促进学生成长，为学生提供更为实惠、优质的文化生活服务。</div>,
        img: '/img/yingyuan_bg.png',
        to: '/product/9',
    }, {
        title: '智能门锁',
        desc: '让校园管理更便利,更安全。',
        icon: '/img/mensuo_icon.png',
        content:
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;随着教育信息化的推进，后勤信息化、宿舍信息化成为了高校智慧校园建设的重要组成部分，而宿舍信息化首要问题就是门锁安全与管理。普通钥匙易丢失，财产安全隐患大；新生宿舍分配、登记工作效率低，老生宿舍回收再分配工作繁杂；宿舍房间和学生数量庞大，原始宿管方法依赖人工成本高。基于以上背景和问题，顶联专门针对安防及后勤管理推出了定制化解决方案，满足开门授权、安全防控、数据统计、巡更及假期管理等功能要求。一方面，极大减少了宿管人员的工作量，实现了宿管工作由“汗水管理”向“智慧管理”转变；另一方面，智能门锁与管理平台宿管系统、迎新系统等对接，实时采集学生开门记录，对晚归、未归、旷课等行为信息统计分析，为学校的日常管理提供数据支撑，并将各类异常信息实时推送至管理员，较大限度的确保宿舍安全。</div>,
        img: '/img/mensuo_bg.png',
        to: '/product/10',
    }, {
        title: '超卡通',
        desc: '万物互联,安全支付。',
        icon: '/img/chaokatong_icon.png',
        content:
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;随着校园数字化建设的浪潮及学生消费模式的改变，手机端应用与服务成为了必备内容。顶联超卡通作为覆盖校园全消费场景的“身份识别+便捷支付”聚合平台，支持学生实体一卡通、电子扫码、超级SIM卡接入，并为校园商户提供移动支付服务解决方案，商家一次接入，即可完成所有主流平台的移动支付服务；同时，超卡通服务快捷、准确的身份识别技术在高校场景的各个环节发挥了关键作用，成为门禁、消费、考勤、会议签到等子系统中有效的ID编码，被集成在校园智慧管理的各个流程，真正满足学生“一部手机，走遍校园”的需求。</div>,
        img: '/img/chaokatong_bg.png',
        to: '/product/11',
    }, {
        title: '校园网',
        desc: '联合三大通信巨头,共同推进校园信息化建设。',
        icon: '/img/xiaoyuanwang_icon.png',
        content:
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;随着教育信息化的发展，智慧校园建设速度加快，智能终端、物联网终端呈现爆发式增长，出现了更多网络管理和数据安全隐患问题，特别是后疫情时代，在线学习、全息教育等新型业务对网络带宽、时延提出新需求，校园网作为智慧校园底层网络支撑，建设和升级势在必行。基于此，顶联校园网投资运营按照“强核心、轻接入”的建设理念，为高校提供投资、规划、建设、运营、运维一体化服务模式，为学校建设一张可管可控、覆盖全校的有线网络+无线WIFI一体化网络，并持续升级。在方便师生上网的同时，也将先进的ICT技术融合其中，对上网产生的数据进行了抓取和分析，开发了校园网预警分析系统，帮助校方一次性提升信息化建设水平。而且，校园网服务提供专业后期维护，解决了学校自营自建的资金、运维、应用环境压力。</div>,
        img: '/img/xiaoyuanwang_bg.png',
        to: '/product/12',
    }, {
        title: '5G校园',
        desc: '5G,让校园生活更智能。',
        icon: '/img/xiaoyuan_icon.png',
        content:
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在5G技术下，智能化的生活场景被越来越多的人讨论。针对最大的年轻群体学生来说，5G的应用迸发出巨大的发展潜能，在很大程度上改善了校园内外部环境。为加快校园5G网络布局，顶联将5G校园服务列入重点业务板块，基于5G+MEC技术构建5G校园虚拟业务专网,实现5G与校园网的深度融合，致力发展“5G+教育”、“5G+科研”、“5G+应用”。借助5G、大数据、云计算、物联网等新技术构建覆盖全校、互联互通、开放共享的网络服务能力，打造智慧校园平台，推出全面高效的5G智慧校园解决方案，助力教学质量提升，实现场景化、沉浸式、互动式的创新教学，让学习更加智慧高效。</div>,
        img: '/img/xiaoyuan_bg.png',
        to: '/product/3',
    }];


export const platforms = (showBr) => [
    {
        title: '云智数据智能管理平台',
        desc: '智能决策、智能客服、智能工单、',
        img: '/img/yunzhi_p_bg.png',
        homeImg: '/img/yunzhi_home_img.png',
        type: 'yunzhi',
        to: '/platformDetail/0',
        m_img: 'img/mobile/m_yunzhi_home.png',
    }, {
        title: '云彩应用管理系统',
        desc: '运营端小程序，消费端小程序、第三方系统植入等',
        img: '/img/yuncai_p_bg.png',
        homeImg: '/img/yuncai_home_img.png',
        type: 'yuncai',
        to: '/platformDetail/1',
        m_img: 'img/mobile/m_yuncai_home.png',
    }, {
        title: '云数数据管理平台',
        desc: '数据仓储及数据建模分析服务',
        img: '/img/yunshu_p_bg.png',
        homeImg: '/img/yunshu_home_img.png',
        type: 'yunshu',
        to: '/platformDetail/2',
        m_img: 'img/mobile/m_yunshu_home.png',
    }, {
        title: !showBr ? '云物校园生活服务管理系统' : <div>云物<br />校园生活服务<br />管理系统</div>,
        desc: 'IOT项目管理',
        img: '/img/yunwu_img_p.png',
        homeImg: '/img/yunwu_home_img.png',
        type: 'yunwu',
        to: '/platformDetail/3',
    }, {
        title: !showBr ? '云网校园网络服务管理系统' : <div>云网<br />校园网络服务<br />管理系统</div>,
        desc: '校园网络运营项目全周期服务',
        img: '/img/yunwang_img_p.png',
        type: 'yunwang',
        homeImg: '/img/yunwang_home_img.png',
        to: '/platformDetail/4',
    }, {
        title: !showBr ? '校园后勤管理系统' : <div>校园后勤<br />管理系统</div>,
        desc: '人事、财务、学工、科研管理',
        img: '/img/houqin_img_p.png',
        type: 'houqin',
        homeImg: '/img/houqin_home_img.png',
        to: '/platformDetail/5',
    }, {
        title: '迎新宿管系统',
        desc: '身份认识及宿舍管理',
        img: '/img/yingxin_img_p.png',
        type: 'yingxin',
        homeImg: '/img/yingxin.png',
        to: '/platformDetail/6',
    }, {
        title: !showBr ? '平安校园管理预警系统' : <div>平安校园<br />管理预警系统</div>,
        desc: '自主研发的校园预警平台，通过自身的努力，保障学生的身心安全，协助学校共同打造智慧且安全的校园环境。',
        img: '/img/pingan_p_bg.png',
        homeImg: '/img/xiaoyuan_home_img.png',
        type: 'pingan',
        to: '/platformDetail/7',
    }, {
        title: '云通统一支付系统',
        desc: '统一收单服务',
        img: '/img/yuntong_p_bg.png',
        homeImg: '/img/yuntong_home_img.png',
        type: 'yuntong',
        to: '/platformDetail/8',
    }, {
        title: '云芯卡管理系统',
        desc: '校园卡管理及服务',
        img: '/img/yunxin_img_p.png',
        homeImg: '/img/yunxin_home_img.png',
        type: 'yunxin',
        to: '/platformDetail/9',
    }, {
        title: '云合物联网网关系统',
        img: '/img/yunhe_img_p.png',
        homeImg: '/img/yunhe_home_img.png',
        type: 'yunhe',
        to: '/platformDetail/10',
        m_img: 'img/mobile/m_yunhe_home.png',
    }];

//解决方案
export const SOLUTIONS_HEADER_LIST = [
    {
        title: '投建运营',
        children: [
            {
                title: '智慧网络',
                icon: '/img/solution/common/header_icon/zhihuiwangluo.png',
                type: 'net',
                to: '/solutions/net',
            },
            {
                title: '智慧生活',
                icon: '/img/solution/common/header_icon/zhihuishenghuo.png',
                type: 'live',
                to: '/solutions/live',
            },
            {
                title: '智慧零售',
                icon: '/img/solution/common/header_icon/zhihuilingshou.png',
                type: 'sell',
                to: '/solutions/sell',
            },
        ]
    },
    {
        title: '智慧后勤',
        children: [
            {
                title: '新一代一卡通',
                icon: '/img/solution/common/header_icon/xinyidaiyikatong.png',
                type: 'card',
                to: '/solutions/card',
            },
            {
                title: '智慧能源',
                icon: '/img/solution/common/header_icon/zhihuinengyuan.png',
                type: 'energy',
                to: '/solutions/energy',
            },
            {
                title: '智慧通行',
                icon: '/img/solution/common/header_icon/zhihuitongxing.png',
                type: 'pass',
                to: '/solutions/pass',
            },
            {
                title: '智慧考勤',
                icon: '/img/solution/common/header_icon/zhihuikaoqin.png',
                type: 'attendance',
                to: '/solutions/attendance',
            },
            {
                title: '智慧报修',
                icon: '/img/solution/common/header_icon/zhihuibaoxiu.png',
                type: 'repairs',
                to: '/solutions/repairs',
            },
        ]
    },
    {
        title: '智慧学工',
        children: [
            {
                title: '智慧迎新',
                icon: '/img/solution/common/header_icon/zhihuiyingxin.png',
                type: 'welcome',
                to: '/solutions/welcome',
            },
            {
                title: '学工管理',
                icon: '/img/solution/common/header_icon/xuegongguanli.png',
                type: 'learn',
                to: '/solutions/learn',
            },
            {
                title: '智慧宿管',
                icon: '/img/solution/common/header_icon/zhihuisuguan.png',
                type: 'dormitory',
                to: '/solutions/dormitory',
            },
            {
                title: '智慧档案',
                icon: '/img/solution/common/header_icon/zhihuidangan.png',
                type: 'record',
                to: '/solutions/record',
            },
            {
                title: '校友会',
                icon: '/img/solution/common/header_icon/xiaoyouhui.png',
                type: 'alumni',
                to: '/solutions/alumni',
            },
        ]
    },
    {
        title: '数据服务',
        children: [
            {
                title: '数据治理',
                icon: '/img/solution/common/header_icon/shujuzhili.png',
                type: 'data-govern',
                to: '/solutions/data-govern',
            },
            {
                title: '数字孪生',
                icon: '/img/solution/common/header_icon/shuziluansheng.png',
                type: 'digital-twin',
                to: '/solutions/digital-twin',
            },
        ]
    },
    {
        title: '融合门户',
        children: [
            {
                title: '门户/APP',
                icon: '/img/solution/common/header_icon/menhu_app.png',
                type: 'portal',
                to: '/solutions/portal',
            },
        ]
    },
]

//header标题
export const drawerData_Temporary = [
    {
        module: '解决方案',
        type: 'solution',
        style: { width: '800px', margin: '0 auto', display: 'flex', justifyContent: 'space-around' },
        children: [...SOLUTIONS_HEADER_LIST.map(ele => ({
            ...ele,
            title: `${ele.title}`
        }))],
    },
    {
        module: '产品',
        type: 'product',
        style: { width: '950px', margin: '0 auto' },
        children: [
            {
                title: '节水控制',
                icon: '/img/节水.png',
                type: 'water_saving',
                children: WATER_SAVING_PRODUCTS_Temporary.map(ele => {
                    return {
                        ...ele,
                        title: `${ele.title}`,
                    };
                }),
            },
            {
                title: '共享服务',
                icon: '/img/共享.png',
                type: 'shared',
                children: SHARE_PRODUCTS_Temporary.map(ele => {
                    return {
                        ...ele,
                        title: `${ele.title}`,
                    };
                }),
            },
            {
                title: '智能消费',
                icon: '/img/消费.png',
                type: 'consumer',
                children: CONSUMPTION_LIFE_PRODUCTS_Temporary.map(ele => {
                    return {
                        ...ele,
                        title: `${ele.title}`,
                    };
                }),
            },
            {
                title: '校园网络',
                icon: 'img/校园.png',
                type: 'network',
                children: SCHOOLNET_LIFE_PRODUCTS_Temporary.map(ele => {
                    return {
                        ...ele,
                        title: `${ele.title}`,
                    };
                }),
            },
            {
                title: '智能通道',
                icon: 'img/通道.png',
                type: 'channel',
                children: CHANNEL_PRODUCTS_TEMPORARY.map(ele => {
                    return {
                        ...ele,
                        title: `${ele.title}`,
                    };
                }),
            },
            {
                title: '停车管理',
                icon: '/img/停车.png',
                type: 'parking',
                children: PARK_PRODUCTS_TEMPORARY.map(ele => {
                    return {
                        ...ele,
                        title: `${ele.title}`,
                    };
                }),
            },
            {
                title: '智能门锁',
                icon: '/img/门锁.png',
                type: 'door_lock',
                children: LOCK_PRODUCTS_TEMPORARY.map(ele => {
                    return {
                        ...ele,
                        title: `${ele.title}`,
                    };
                }),
            },
            {
                title: '智能教室',
                icon: '/img/教室.png',
                type: 'classroom',
                children: CLASSROOM_PRODUCTS_TEMPORARY.map(ele => {
                    return {
                        ...ele,
                        title: `${ele.title}`,
                    };
                }),
            },
            {
                title: '移动应用',
                icon: '/img/移动应用.png',
                type: 'app',
                children: APP.map(ele => {
                    return {
                        ...ele,
                        title: `${ele.title}`,
                    };
                }),
            },
        ],

    },
    {
        module: '商业合作',
        type: 'cooperate',
        style: { width: '140px', margin: '0 auto' },
        children: [
            {
                title: '成为顶联合作者',
                to: '/cooperate/why',
            }, {
                title: '合作模式',
                to: '/cooperate/moshi',
            }, {
                title: '合作伙伴',
                to: '/cooperate/partner',
            }, {
                title: '合作案例',
                to: '/cooperate/anli',
            }, {
                title: '合作院校',
                to: '/cooperate/yuanxiao',
            },],
    },
    {
        module: '关于我们',
        type: 'about',
        style: { width: '100px', margin: '0 auto' },
        children: [
            {
                title: '公司简介',
                to: '/about/intro',
            }, {
                title: '发展历程',
                to: '/about/history',
            }, {
                title: '荣誉资质',
                to: '/about/honor',
            }, {
                title: '新闻中心',
                to: '/news',
            }, {
                title: '联系我们',
                to: '/about/contact',
            }],
    }];

//招聘
export const dutyList = [
    {
        title: '客户经理',
        content: <div className={styles.content}>
            1. 负责项目销售工作，完成个人任务指标；<br />
            2. 负责项目统筹、交付与追加；<br />
            3. 负责协助运营、运维工作开展。
        </div>,
        duty: <div className={styles.content}>
            1. 大专及以上学历，35周岁以内（条件优秀者可放宽年龄）；<br />
            2. 熟悉教育或ICT行业，具备区域院校客户资源，主导过项目成交、落单；<br />
            3. 具备5年及以上、且5个以上项目销售、管理及运作经验。<br />
            Base地：济南&nbsp;&nbsp;合肥&nbsp;&nbsp;北京&nbsp;&nbsp;西安&nbsp;&nbsp;长春&nbsp;&nbsp;福建
        </div>,
    }, {
        title: '售前技术支持',
        content: <div className={styles.content}>
            1. 负责项目需求收集、方案设计、方案呈现、文件撰写；<br />
            2. 负责项目地勘与交付；<br />
            3. 其他工作。档案管理、内部培训、资料管理。
        </div>,
        duty: <div className={styles.content}>
            1. 本科及以上学历，具备较强的逻辑思维、文案能力和沟通表达能力；<br />
            2. 具备3年以上教育信息化软硬件建设售前支持经验；<br />
            3. 计算机网络相关专业，熟悉国内外网络设备厂商产品（如交换机、无线设备），具有CCNP、 HCSE等相关厂家认证优先。<br />
            Base地：济南&nbsp;&nbsp;合肥&nbsp;&nbsp;北京&nbsp;&nbsp;西安&nbsp;&nbsp;长春&nbsp;&nbsp;福建
        </div>,
    }, {
        title: '营销经理',
        content: <div className={styles.content}>
            1. 负责已交付校园宽带套餐、相关增值服务以及生活类服务运营、拓展和存量客户的维护；<br />
            2. 调研校园C端客户需求，完善校园宽带产品、增值业务、周边生态服务等平台运营模式；<br />
            3. 拓展校园相关信息化项目，做好生态伙伴、电信运营商等相关方协作工作；<br />
            4. 校园运营团队的日常管理、考核调度，组织开展营销活动。
        </div>,
        duty: <div className={styles.content}>
            1. 本科及以上学历，管理、市场营销、电商、计算机、信息相关专业优先；<br />
            2. 3年及以上市场营销策划经验，有大型平台生活服务、短视频、电商相关业务经验者优先；<br />
            3. 熟悉校园客户消费习惯，具备校园客户及信息化项目拓展运营经验；<br />
            4. 有一定营销团队运营管理能力。
        </div>,
    }
];

//发展历程
export const timeLine = [
    {
        date: '【生态】2022-至今',
        desc: ' 围绕教、学、研、管、服、评五个教育数字化场景，发展为集自主研发软、硬件+投建运营服务的融合型企业。坚持开放、多元合作，在山西、广州、安徽等多地设立子分公司，融合上下游企业构建智慧校园服务新生态。',
        img: '/img/2021.png',
        position: 'right',
    }, {
        date: '【升级】2019-2021',
        desc: '深耕高校市场，增加生活服务类服务，布局校园支付领域，自研“云联”生活服务和“新一代一卡通”支付系统。各平台数据逐步打通，打破各业务系统隔阂，实现校园场景下全量全要素数据连接。',
        img: '/img/2018-2020.png',
        position: 'left',
    }, {
        date: '【转型】2013-2018',
        desc: ' 专注服务高校用户，提供高校网络项目投建运营服务，研发“云顶”网络服务管理系统。2016年，顶联信息新三板挂牌；2018年成为神州数码集团旗下教育产业服务公司，同年更名。',
        img: '/img/2016-2018.png',
        position: 'right',
    }, {
        date: '【起航】2007-2012',
        desc: ' 2007年9月，前身山东顶联信息技术有限公司成立。 提供高校信息化基础设施建设及校园业务管理软件服务。以承建高新实验室学生机房为主，自研顶联云桌面系统替代传统笨重、高能耗的PC大主机，实现高效云办公。',
        img: '/img/2013-2016.png',
        position: 'left',
    }];

//合作案例
export const SCHOOL = [
    {
        title: '中国科学技术大学',
        content: <div className={NewsStyle.schools_container}>
            <div className={NewsStyle.title}>同“新”协力，中科大迎新再升级</div>
            <img src={'/img/schools/img_0.png'} />
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;中国科学技术大学（以下简称“中科大”）是中国科学院直属的综合性全国重点大学，位列“双一流”建设高校(A类)、985工程、211工程，是国家高质量人才培养和高水平科学研究的重要基地。2017年9月，学校入选全国首批世界一流大学和世界一流学科建设高校。‍</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;继2021年神州顶联为中科大提供新生核验服务，今年双方再度深化合作，开展本科生的迎新工作，并据校方新需求升级解决方案。</div>
            <div className={NewsStyle.subTitle}>刷脸即核验，简省报到流程</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;严格审核新生身份，配备8台升级版核验设备，入学照、身份照、现场照对比仅需1秒，严防冒名顶替入学。刷脸完成，后台自动生成数据，学校自主批量导出所需数据，快捷筛选未核验新生名单。</div>
            <img src={'/img/schools/news81.jpg'} />
            <div className={NewsStyle.img_description}>（身份核验机）</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;同以往耗时排队、手动填写信息不同，今年新生在统一地点完成身份认证，即可完成入学报到，既简省办理过程，优化报到体验，同时又避免疫情期间形成人员聚集现象。</div>
            <img src={'/img/schools/img_2.png'} />
            <div className={NewsStyle.img_description}>（新生身份认证现场）</div>
            <div className={NewsStyle.subTitle}>定制化系统，满足个性需求</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;核验系统实时数据可视化，支持增设定制功能。按学校要求，新生核验结果在大屏显示，学生信息按核验顺序呈环形滚动，数据流转过程全员可见，既增加现场交互趣味性，又提升学生校园归属感。</div>
            <img src={'/img/schools/img_3.png'} />
            <div className={NewsStyle.img_description}>（迎新大屏）</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;大数据判筛选个人信息，实现学校提前为当天过生日的学生准备蛋糕。同时，大屏内置生日标注功能，用个性化服务为新生到校的第一天留下良好印象，展现学校人文关怀。</div>
            <img src={'/img/schools/img_4.png'} />
            <div className={NewsStyle.img_description}>（校领导祝福新生生日快乐）</div>
            <div className={NewsStyle.subTitle}>高精度搜索，实现以图搜人</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;系统内置智能工具—“以图搜人”，基于AI技术，建立多场景组合应用。通过人脸特征提取及人像高精度搜索，迅速在学校数据库里找到匹配者，检索学生基本信息、档案信息。在未来智慧校园建设中，迎新系统与“AI校园”平台互通，可达到对监控画面进行安防人像搜索，过筛在校可疑人员，保障校园安全。</div>
            <img src={'/img/schools/img_5.png'} />
            <div className={NewsStyle.img_description}>（功能界面示意图）</div>
            <div className={NewsStyle.subTitle}>专业化运维，提供即时服务</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;为确保迎新流程顺利进行，神州顶联提供驻场工作人员实时解答问题，与校方一起圆满完成迎新。服务部署周到细致，使新生切实体会到学校的温暖。</div>
            <img src={'/img/schools/img_6.png'} />
            <div className={NewsStyle.img_description}>（校方工作人员工作现场）</div>
            <img src={'/img/schools/img_7.png'} />
            <div className={NewsStyle.img_description}>（神州顶联工作人员现场协助迎新工作）</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;未来，神州顶联将与中国科学技术大学深入探寻更多合作可能，把握用户真实诉求，结合自身实力进行接地转化，满足个性化建设需求，助推学校数字化转型。</div>
        </div>
    },
    {
        title: '山东第一医科大学',
        content: <div className={NewsStyle.schools_container}>
            <div className={NewsStyle.title}>神州顶联助力山东第一医科大学高校迎新</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;山东第一医科大学（简称“山一大”），2018年11月经教育部批准由泰山医学院、山东省医学科学院、山东省立医院、山东省千佛山医院等资源整合组建而成。是山东省重点建设大学、山东省医学科学研究机构、第一批卓越医生教育培养计划项目试点高校、山东特色名校工程立项建设单位。总占地面积6000余亩，现有在校生24000余人，教职工14000余人。</div>
            <img src={'/img/schools/img_8.png'} />
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;金秋九月，又逢入学季，山一大新生们正陆续走入校园，迎接全新大学生活。不同以往繁忙拥挤、排长龙的迎新场景，2022年秋开，神州顶联为山一大提供定制化智能迎新方案，采用“线上信息采集、线下快速报到”的方式保障工作有序、高效开展。</div>
            <div className={NewsStyle.subTitle}>迎新工作前置，全面线上办理</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;内设入学须知、答疑模块、现场报到单等功能，帮助新生提前了解校园和迎新流程，使其不再对校园一无所知。</div>
            <img src={'/img/schools/img_9.png'} />
            <div className={NewsStyle.img_description}>（学生登入迎新平台）</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;同时，学校可根据学生填报的个人和到校信息，预先设置迎新方案相关细节，实现各部门信息实时同步，确保工作高效协同。例如安排接送车辆和新生到站点、按需预备军训服等。</div>
            <img src={'/img/schools/img_10.png'} />
            <div className={NewsStyle.img_description}>（学校安排接送车辆）</div>
            <div className={NewsStyle.subTitle}>现场自助报到，快速完成迎新</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;新生线上提前录入信息，线下直接刷脸进校，在神州顶联工作人员协助下，将现场照、身份证件照两两比对，进行身份核验，严防冒名顶替。核验经确认、上交学校所需材料后，新生自助报到环节就此结束。</div>
            <img src={'/img/schools/img_11.png'} />
            <div className={NewsStyle.img_description}>（神州顶联工作人员协助新生使用人脸识别机）</div>
            <div className={NewsStyle.subTitle}>数据互通互享，实时把控迎新</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“新生报到情况怎样？”“迎新工作进展如何？”心系学生的校领导、老师们可以随时得到问题答案——迎新大屏实时呈现济南、泰安两校区的学生报到信息等现场数据，通过共享数字迎新平台，实现各部门高效数字化协同，为校方提供正确的事项办理决策依据。</div>
            <img src={'/img/schools/img_12.png'} />
            <div className={NewsStyle.img_description}>（现场迎新状况一目了然）</div>
            <img src={'/img/schools/img_13.png'} />
            <div className={NewsStyle.img_description}>（数据同步至行政楼大屏）</div>
            <div className={NewsStyle.subTitle}>统计分析数据，赋能校园决策</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;现场报到结束后，神州顶联提供多维度迎新数据分析报告，帮助学校积累总结经验，提高迎新服务质量。</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;迎新工作圆满落下帷幕，新生们正式开始了大学生活，神州顶联也将继续与山一大深化合作，携手推动校园数字化转型。</div>
        </div>
    },
    {
        title: '山东建筑大学',
        content: <div className={NewsStyle.schools_container}>
            <div className={NewsStyle.title}>与时偕行，神州顶联助山东建筑大学探索实践智慧校园建设</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;山东建筑大学（以下简称“山建”）是一所以工科为主，建筑类专业为特点，多学科渗透发展的综合性大学。为山东省人民政府与中华人民共和国住房和城乡建设部共建高校、国家教育部卓越工程师教育培养计划高校、服务国家特殊需求博士人才培养项目高校、国家“产教融合”项目首批建设高校、首批山东省应用型人才培养特色名校、一带一路建筑类高校联盟成员。</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;近年来学校深入推动信息化建设，落实智慧校园基础建设，强化信息化对学校管理、教学和科研的赋能作用。</div>
            <img src={'/img/schools/img_14.png'} />
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;在疫情防控常态化背景下，近3万人的人员安全管理和效能管理面临严峻考验，亟需人工智能和大数据技术，实现减员增效的效果。</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;山建自2016年开始建设智慧校园项目，实现安防、人员等多个领域的智慧化管理。目前已经建设全校一张网、平安校园二期、智慧宿管、学生行为分析、智慧后勤一期。</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;人工智能技术的应用，实现学校原有管理模式升级，有效提升校园人员管理力度和服务覆盖广度，真正体现出“智慧校园”的价值和特点。</div>
            <img src={'/img/schools/img_15.png'} />
            <div className={NewsStyle.img_description}>（神州顶联驻校网络运维人员）</div>
            <div style={{ display: 'flex', justifyContent: 'space-between', height: 210 }}>
                <div>
                    <img src={'/img/schools/img_16.png'} />
                    <div className={NewsStyle.img_description}>（人脸识别）</div>
                </div>
                <div>
                    <img src={'/img/schools/img_17.png'} />
                    <div className={NewsStyle.img_description}>（楼宇布控）</div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: 300, height: 230 }}>
                    <img src={'/img/schools/img_18.png'} />
                    <div className={NewsStyle.img_description}>（平安系统大数据管理平台）</div>
                </div>
                <div style={{ width: 300, height: 230 }}>
                    <img src={'/img/schools/img_19.png'} />
                    <div className={NewsStyle.img_description}>（宿舍门禁-助力学生管理工作）</div>
                </div>
            </div>
            <img src={'/img/schools/img_20.png'} />
            <div className={NewsStyle.img_description}>（宿舍区域无感布控--实况监控）</div>
        </div>
    },
    {
        title: '齐鲁师范学院',
        content: <div className={NewsStyle.schools_container}>
            <div className={NewsStyle.title}>智慧迎新，科技战疫——齐鲁师范学院应用“AI无接触”技术助力新学年</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;齐鲁师范学院是一所公办全日制普通本科师范院校，学校设历下和章丘两个校区，学校设有16个教学单位，39个普通本科专业，全日制在校生17152人。学校在服务山东基础教育方面优势突出，是教育部国家名校长领航工程基地、“国培计划”精品项目基地；建有山东省中小学师训干训中心、中小学课程研究中心等机构，承担全省中小学师资培训任务，每年培训80余万人。</div>
            <img src={'/img/schools/img_21.png'} />
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;学校近年来快速扩招，全日制在校生已达
                17000余人。大面积的人口流动给原有的迎新模式和管理能力带来挑战。
            </div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;为保证疫情期间的安全管理，2020年，齐鲁师范学院全面升级为“AI无接触迎新”。</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AI无接触迎新技术基于现场人脸识别和后台身份识别，自动甄别比对照片，严防替考替学等异常行为出现，简化工作人员工作步骤、避免重复性工作。</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;使用人工智能迎新技术，不仅为学校信息化进程打下坚实数据底座，也给学校新生带来与众不同的感受，充分向新生以及家长展现了学校科学化管理水平和能力，留下入校日的最佳初印象。</div>
        </div>
    },
    {
        title: '济南职业学院',
        content: <div className={NewsStyle.schools_container}>
            <div className={NewsStyle.title}>智慧联动，神州顶联携手济南职业学院建设现代化校园</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;济南职业学院（以下简称“济职”）是由济南市政府举办的全日制高等职业院校，是山东省示范高职院校，国家级骨干高职院校，学校有66年的高等教育办学史和20年的高职教育办学史，培养毕业生10余万人。学校面向全国10个省（区）招生，现有普通全日制高职在校生12500余人。</div>
            <img src={'/img/schools/img_22.png'} />
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;为助推济职的校园信息化、系统化、可视化建设，神州顶联与山东移动合作推进济职智能门锁项目，于2021年6月介入客情，开始摸查客户门锁需求，7、8月达成合作意向并签订合同，投建智能门锁及智慧宿管系统。</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;以智慧宿管系统代替传统的纯人工管理方式，一改以往宿舍管理“高成本低效率”的状态，优化舍区宿管服务，提升校园信息化建设。</div>
            <img src={'/img/schools/img_23.png'} />
            <div className={NewsStyle.img_description}>（扫码开启智能门锁）</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;门锁更新后，学生通过手机刷超卡（即超级SIM卡）即可2秒熄屏开锁，极大的方便了学生的宿舍生活。同时超卡不可被复制，有效保障宿舍的人、财、物安全。</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;宿舍智能门锁的改造升级，不仅最大限度提升宿舍管理质量，还有效提升宿舍美观度，获得管理员和95%以上学生的好评。</div>
        </div>
    },
    {
        title: '西安交通大学城市学院',
        content: <div className={NewsStyle.schools_container}>
            <div className={NewsStyle.title}>神州顶联助力西安交通大学城市学院宿舍升级，共促校园信息化发展</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;西安交通大学城市学院（以下简称“西交城”），是经国家教育部批准设立的全日制本科层次的独立学院，现有本科在校生9700余名。围绕“以学生为主体，以教师为主导，以育人为宗旨，以教学为中心”的办学理念，提升校园人文水平。</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2022年9月开学季，西安交通大学城市学院携手神州顶联为学生升级宿舍服务，共计安装2000余把宿舍智能门锁及配备智慧宿管系统。</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;校园的信息化建设在一定程度上决定着管理、办学、服务水平。西交城自成立之初就非常重视信息化建设，投入大量资金进行校园新基建。此次西交城升级宿舍，旨在提升学生体验、优化学生工作，夯实校园信息化建设的数字底座。</div>
            <img src={'/img/schools/img_24.png'} />
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;传统人工宿舍管理存在较多问题，加之后疫情期间人员出入管理的压力，许多校园暴露出了更多弊端。诸如学生进校、离校，只能采取人工收、发钥匙，还需逐一核实各寝室离寝状况；查寝时，无法精准到个人的未归、晚归的情况；因学生忘带钥匙、丢失钥匙而引发的宿舍安全隐等等。</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;神州顶联凭借十五年对智慧校园建设的丰富经验，以及校园业务场景的深刻理解，通过数字技术下的可视化管理，以方便学生生活、提升住宿体验为方向，着力解决当前校园宿舍问题。</div>
            <div className={NewsStyle.subTitle}>1.智能门锁实时在线，实现宿舍可视化管理</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;实现线上发钥、下放权限、宿舍分配，实时查看宿舍的门、锁、电池等使用情况，保证设备状态可控；可自行设置在校日与休息日的不同归寝时间，实时检查学生归寝状态，根据开锁动态的数据对学生行为规范进行分析，简化宿舍管理工作的同时，保障学生安全。</div>
            <img src={'/img/schools/img_25.png'} />
            <div className={NewsStyle.img_description}>（智慧宿管平台管理界面）</div>
            <div className={NewsStyle.subTitle}>2.多种安全开锁方式，带来全新生活体验</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;同时支持刷手机、支付宝/微信扫码、机械钥匙三种开锁方式，简易便捷，出入方便。</div>
            <img src={'/img/schools/img_26.png'} />
            <div className={NewsStyle.img_description}>（刷手机开门）</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;为防止学生因丢失钥匙带来的安全隐患，智能门锁和其配套钥匙使用特殊工艺，不易被破解，有效保障宿舍人、财、物安全。</div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <img style={{ width: 340, height: 400 }} src={'/img/schools/img_27.png'} />
                <img style={{ width: 340, height: 400 }} src={'/img/schools/img_28.png'} />
            </div>
            <div className={NewsStyle.img_description}>（宿舍面貌焕然一新）</div>
            <div className={NewsStyle.subTitle}>3.7*24小时专业运维，确保设备稳健运行</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;当门锁出现掉线等安全问题，驻地运维人员会在管理平台收到报修消息，30分钟内到达现场，保障学生使用体验。</div>
            <img src={'/img/schools/img_29.png'} />
            <div className={NewsStyle.img_description}>（平台报修记录界面）</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;神州顶联践行“优化校园智能管理”的使命，协助西交城打造新基建底座，助力学校实现教育信息化建设目标。未来双方将持续探寻校园其它场景的合作机会，开启共建共赢新篇章。</div>
        </div>
    },
    {
        title: '山东商业职业学院',
        content: <div className={NewsStyle.schools_container}>
            <div className={NewsStyle.title}>“汗水管理”变“智慧管理”——山东商业职业学院升级宿舍服务</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;山东商业职业学院是经教育部批准设立的省属高职院校。学校占地2600余亩，校舍建筑面积42万余平方米，资产总值10亿多元，教学仪器设备总值1.8亿元；设有7个二级学院，开设51个专科专业；有全日制在校生15000余人。</div>
            <img src={'/img/schools/img_30.png'} />
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;提升宿舍信息化的重要任务是解决宿舍门锁安全问题及管理漏洞。传统钥匙易丢失，财产安全隐患大；新生宿舍分配、登记工作效率低，宿舍管理工作繁杂；宿舍房间和学生数量庞大，宿舍管理依赖人工，花费大量时间、精力。</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;基于山东商业职业技术学院的宿舍管理问题，2021年，神州顶联推出了定制化解决方案，满足开门授权、安全防控、数据统计、巡更及假期管理等功能要求。</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;学校升级宿舍门锁后，极大减少了宿管人员的工作量，实现宿管工作由“汗水管理”向“智慧管理”转变。</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;智能门锁与宿管系统、迎新系统等平台互通，可实时采集学生开门数据记录，对晚归、未归、旷课等行为信息进行统计分析，为学校的日常管理提供数据支撑，将各类异常信息实时推送至管理员，较大限度确保学生安全，简化人力管理成本，校方对此十分满意。</div>
        </div>
    },
    {
        title: '山东传媒职业学院',
        content: <div className={NewsStyle.schools_container}>
            <div className={NewsStyle.title}>聚焦“底子”功夫，构建校园安全防护墙</div>
            <div>山东传媒职业学院隶属于山东省广播电视局，由省教育厅共管，是省内唯一一所广播影视及大众传媒类国有公办普通高校。</div>
            <img src={'/img/schools/img_31.png'} />
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;近年来，山东传媒职业学院全力推进信息化提档升级，强化网络基础设施建设，提升服务质量。神州顶联自2016年开始，为山东传媒职业学院部署高带宽、高可靠、易管易维的“校园网解决方案”，打造无盲区、高性能校园网络体系，强力支撑智慧校园数字底座。</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;项目由神州顶联出资投建，采用业界领先的网络技术，分场景设计，迎合日益爆发的终端接入趋势，满足学校网建需求，真正实现学校省心、省力、省钱。</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;为缓解学校人员配置压力，神州顶联提供7*24小时网络运维支持，为师生建立一张高速稳定、持续升级的优质网络，学校无需投入人力、物力、财力。</div>
            <div className={NewsStyle.subTitle}>可视化大数据预警平台</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;山东传媒职业学院不仅注重网络基础设施升级，更以前瞻视野，深度挖掘网络数据价值。与神州顶联合力研发可视化大数据预警平台，基于上网数据、习惯分析，形成学生思想特征精准画像。</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;学校拥有平台最高管理权限，可随时随地管控师生网络行为及安全风险。同时，管理员可通过自定义浏览关键词，实现对网贷风险、情绪异常、疾病、涉嫌失联等行为的预警，提前干预、疏导，保障学生身心健康。真正实现教育决策由经验驱动向数据驱动转变，由被动响应向主动服务转变。</div>
            <img src={'/img/schools/img_32.png'} />
            <div className={NewsStyle.img_description}>（山东传媒职业学院大数据预警平台）</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;未来，双方将不断深化智慧校园场景合作，让信息化成为山东职业学院人才培养的强劲力量，努力构建教育信息化发展新生态。</div>
        </div>
    },
]

//新闻
export const NEWS = [
    {
        title: '山东省学校后勤协会高校智慧校园（后勤）研究院成立会议顺利召开',
        img: '/img/news/news81.jpg',
        date: '2023-08-12',
        content: <div className={NewsStyle.news_container}>
            <div className={NewsStyle.title}>山东省学校后勤协会高校智慧校园（后勤）研究院成立会议顺利召开</div>
            <div className={NewsStyle.date}>2023-08-12</div>
            <div>8月12日上午，山东省学校后勤协会高校智慧校园（后勤）研究院成立会议在济南召开。山东省学校后勤协会副会长兼秘书长刘学祥和研究院专家团队20余人参加会议。山东省教育发展服务中心学校后勤部部长翟亚锋主持会议。</div>
            <img src={'/img/news/news81.jpg'} />
            <div>刘学祥表示，山东省学校后勤协会充分整合全省高校智慧化建设领域专家资源成立（后勤）研究院，对推动山东高校智能化发展有着十分的重要意义。并指出智慧校园建设已经成为高校管理的焦点，后勤智慧化建设更是校园建设、管理的重地。</div>
            <img src={'/img/news/news82.jpg'} />
            <div>会议审议通过研究院工作规则与组织机构；研究院筹备组孙光辉作筹备工作汇报；以无记名投票的方式，选举产生院长、副院长、秘书长。</div>
            <img src={'/img/news/news83.jpg'} />
            <div>新当选院长刘国梁简要介绍神州顶联情况，并表示将面向山东150余所高校，深入校园各场景，挖真问题，精准提炼，输出行业调研报告，全力推动教育现代化成果转化，助力山东高校智慧后勤、智慧校园建设。持续创新智慧校园平台建设，加强与国内外合作交流，深化交流互鉴，多元化路径助推研究院发展，为国家教育发展事业提供更好的服务。</div>
            <img src={'/img/news/news84.jpg'} />
            <div>研究院成立旨在深入贯彻落实中共中央、国务院《中国教育现代化2035》和教育部《教育信息化2.0行动计划》的总体部署，推动山东省“十四五”教育事业发展规划落地实施，推进新技术与教育教学深度融合，建立山东高校数字化转型新模式和治理能力提升新格局。</div>
        </div>
    },
    {
        title: '神州顶联（山东）物联科技有限公司校园智慧用电改造项目中标公告',
        img: '/img/news/news80.jpg',
        date: '2023-06-25',
        content: <div className={NewsStyle.news_container}>
            <div className={NewsStyle.title}>神州顶联（山东）物联科技有限公司校园智慧用电改造项目中标公告</div>
            <div className={NewsStyle.date}>2023-06-25</div>
            <div>神州顶联（山东）物联科技有限公司校园智慧用电改造项目以一次性竞价（首轮报价即最终报价）的方式组织采购，现将成交人名单公告如下：</div>
            <div>项目名称：神州顶联（山东）物联科技有限公司校园智慧用电改造项目<br />
                成交人：中国移动通信集团山东有限公司泰安分公司<br />
                成交金额：134.9912万元</div>
            <div style={{ textAlign: "right" }}>日期：2023年6月25日<br />
                招标人：神州顶联（山东）物联科技有限公司</div>
        </div>
    },
    {
        title: '神州顶联（山东）物联科技有限公司校园智慧用电改造项目询价书',
        img: '/img/news/news79.jpg',
        date: '2023-06-20',
        content: <div className={NewsStyle.news_container}>
            <div className={NewsStyle.title}>神州顶联（山东）物联科技有限公司校园智慧用电改造项目询价书</div>
            <div className={NewsStyle.date}>2023-06-20</div>
            <div>神州顶联（山东）物联科技有限公司校园智慧用电改造项目以一次性竞价（首轮报价即最终报价）的方式组织采购，特邀请贵单位参加竞价。</div>
            <div>1、 项目名称：神州顶联（山东）物联科技有限公司校园智慧用电改造项目<br />
                2、项目内容：信息化建设，清单后附，报价最低者为成交人。<br />
                3、预算控制价：160万元（报价不得高于预算控制价，否则作为无效报价）。<br />
                4、付款方式：验收合格后一次性支付100%费用。<br />
                5、报价文件递交时间、截止时间、递交地点及地址<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.1递交时间：2023年6月25日13时30分至14时30分；<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.2截止时间：2023年6月25日14时30分；<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.3递交地点：中国(山东)自由贸易试验区济南片区经十路汉峪金谷A6-4号楼1901-1。<br />
                6、联系方式<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6.1 采购人：神州顶联（山东）物联科技有限公司<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;联系方式：15905319282
            </div>
            <div>详情请见附件：<a title={'项目询价书'} href={'https://toplion.com.cn/%E8%AF%A2%E4%BB%B7%E4%B9%A6.docx'}>项目询价书</a></div>
        </div>
    },
    {
        title: '攻城掠地冲刺2023——神州顶联业务启动大会暨产品推介会议圆满结束',
        img: '/img/news/img.png',
        date: '2023-03-04',
        content: <div className={NewsStyle.news_container}>
            <div className={NewsStyle.title}>攻城掠地冲刺2023——神州顶联业务启动大会暨产品推介会议圆满结束</div>
            <div className={NewsStyle.date}>2023-03-04</div>
            <div>2023年3月4日，神州顶联2023年业务启动大会暨产品推介会议在总部济南顺利召开。公司全体人员及各分公司团队、渠道伙伴共300余人参加了本次会议。</div>
            <img src={'/img/news/news61.jpg'} />
            <div className={NewsStyle.subTitle}>战略·文化·管理·指标‍</div>
            <div>会议伊始，总经理刘国梁以“战略·文化·管理·指标”为主题，全面复盘公司从销售型—服务型—融合型的发展历程。强化智慧校园解决方案与投建运营服务商定位；始终围绕“成就公司、成长自己、温暖他人”的企业文化，推进人才队伍建设；首次在大会上宣布公司2023年经营指标；明确“重结果+保增长+高激励+强协同”的管理机制，为圆满完成2023年各项工作及业务目标打下坚实基础。</div>
            <img src={'/img/news/news62.jpg'} />
            <div className={NewsStyle.subTitle}>2023年工作规划报告‍</div>
            <div>销售、运营、产品、综合支持各中心总经理逐一上台进行工作汇报。大家均表示，2023年是企业发展关键的一年，积极主动执行公司的发展战略，以结果为导向，狠抓落实，拒绝内耗。将业务目标逐级分解，落实到人，保质保量完成公司2023年度目标任务。随后公司与各中心、各团队负责人举行了隆重的签约仪式。</div>
            <div style={{ overflowX: "auto", overflowY: "hidden", whiteSpace: "nowrap", height: 410, marginBottom: 0 }}>
                <div style={{ width: "auto", height: '100%', margin: 0 }}>
                    <img src={'/img/news/news63.jpg'} />
                    <img src={'/img/news/news64.jpg'} />
                    <img src={'/img/news/news65.jpg'} />
                    <img src={'/img/news/news66.jpg'} />
                    <img src={'/img/news/news67.jpg'} />
                </div>
            </div>
            <div style={{ color: "red", fontSize: 10, textAlign: "center", margin: 0 }}>左右滑动，查看更多照片</div>
            <div className={NewsStyle.subTitle}>产品·新生态‍</div>
            <img src={'/img/news/news68.jpg'} />
            <div>下午，副总经理王红心对公司产品与解决方案的整体设计思路和优势做了详细介绍。深入、客观、全面分析校园数字化转型的建设阶段与路径，并就公司运营服务类、管理服务类、数据服务类三大模块，打造的智慧生活、智慧网络、智慧学工等12种解决方案进行了简要介绍。同时表示，产品中心正逐步构建“学校—厂商—运营商—服务商”共生互生再生发展的行业新生态。</div>
            <div className={NewsStyle.subTitle}>场景·价值·合作模式‍</div>
            <div style={{ overflowX: "auto", overflowY: "hidden", whiteSpace: "nowrap", height: 410, marginBottom: 0 }}>
                <div style={{ width: "auto", height: '100%', margin: 0 }}>
                    <img src={'/img/news/news69.jpg'} />
                    <img src={'/img/news/news70.jpg'} />
                    <img src={'/img/news/news71.jpg'} />
                    <img src={'/img/news/news72.jpg'} />
                    <img src={'/img/news/news73.jpg'} />
                    <img src={'/img/news/news75.jpg'} />
                    <img src={'/img/news/news76.jpg'} />
                    <img src={'/img/news/news77.jpg'} />
                </div>
            </div>
            <div style={{ color: "red", fontSize: 10, textAlign: "center", margin: 0 }}>左右滑动，查看更多照片</div>
            <div>随后，平台&数据治理、智慧学工、校园安全、校园新生态、智慧教室、一卡通、智慧生活、充电桩、智能终端、校园新零售、校园网宽带运营、校企合作等业务线负责人从应用场景、价值亮点、合作模式等方面对产品进行详细介绍，加强大家对产品设计理念与优势的了解。</div>
            <div className={NewsStyle.subTitle}>新形势·新征程‍</div>
            <div>最后，总经理刘国梁对会议做了总结发言。他表示，神州顶联自研产品从“跟跑”到“并肩”，始终紧密围绕国家智慧校园建设政策与要求，以解决客户需求为导向，以提高用户服务质量为目标，将持续加大自研产品力度，扩大市场占有率，争做“领跑”者。</div>
            <img src={'/img/news/news78.jpg'} />
            <div>2022年，在市场上环境严峻复杂的情况下，我们遇到了许多困难，也经历了许多考验。面对2023年的新形势、新格局，我们将继续踏着坚实的步伐，稳扎稳打走好每一步。保持公司高质量发展，圆满完成各项工作及业务目标，再创新辉煌！</div>
        </div>
    },
    {
        title: '新春致辞|乘势而上开新局，追求卓越再出发',
        img: '/img/news/news59.jpg',
        date: '2023-01-17',
        content: <div className={NewsStyle.news_container}>
            <div className={NewsStyle.title}>新春致辞|乘势而上开新局，追求卓越再出发</div>
            <div className={NewsStyle.date}>2023-01-17</div>
            <div>
                <Video
                    img={'/img/news/news60.jpg'}
                    address={'https://iot-statics.oss-cn-hangzhou.aliyuncs.com/icons/2023NewYearGreeting.mp4'}
                />
            </div>
            <div>董事长刘国梁 新春致辞</div>
            <div>大家好。2023年新春佳节将至，我代表神州顶联科技有限公司向广大用户、合作伙伴、行业同仁，辛勤付出的顶联同学们，以及一直关心、支持神州顶联发展的各界朋友们，致以诚挚的新年祝福。</div>
            <div>2022年，神州顶联积极响应二十大报告提出的“推进教育数字化”号召，践行“以数据驱动业务重构，极大提升用户应用体验”的理念。不断打磨产品与解决方案，围绕教、学、研、管、服、评6个数字化场景，构建软硬一体的服务体系，助推教育数字化转型落地。</div>
            <div>这一年，受疫情、市场、团队等因素影响，是我们企业发展较艰难的一年。学古不泥古，破法不悖法，经过全体人员共同努力，开发了新零售类、校园网络类、新一代一卡通等产品，与上下游企业深度合作，建立密切合作关系，与多所院校签署合作协议，服务用户累计增加。</div>
            <div>2023年，神州顶联强化定位，以推动中国校园智能化跨越式发展为使命，始终坚持智慧校园解决方案与投建运营服务商定位，持续加大产品研发与创新；深化服务，把用户服务放在第一位，完善服务体系，提升服务水平，为用户提供高品质应用体验；引进人才，建立激励相容机制，保持队伍稳定，吸引、培养优秀人才；多元合作，坚持开放、共享、共赢的原则，构建优势互补、可持续发展的生态体系。</div>
            <div>青衿之志，履践致远。神州顶联正值转型升级后的发展上升期，立足新起点，全体顶联人迈着更加铿锵有力的步伐，深耕智慧校园市场，为推动中国教育高质量发展贡献一份力量！</div>
            <div>最后，祝福伟大祖国国泰民安，恭祝大家新年快乐、身体健康、阖家幸福！</div>
        </div>
    },
    {
        title: '暖遇十六载，携手创辉煌——神州顶联16岁生日快乐',
        img: '/img/news/news58.jpg',
        date: '2022-09-24',
        content: <div className={NewsStyle.news_container}>
            <div className={NewsStyle.title}>暖遇十六载，携手创辉煌——神州顶联16岁生日快乐</div>
            <div className={NewsStyle.date}>2022-09-24</div>
            <div>2022年9月24日，神州顶联与时光相约，迎来了十六岁生日。历经十六年的风雨与磨砺，神州顶联变得更加成熟、稳重，并饱含青春热情。</div>
            <div>
                <Video
                    img={'/img/news/news57.jpg'}
                    address={'https://iot-statics.oss-cn-hangzhou.aliyuncs.com/icons/16thAnniversaryVideo.mp4'}
                />
            </div>
            <div>16年根植校园，服务校园。每一步的坚持与付出，都激励着神州顶联持续创新、不断向前。发芽，成长，深耕，壮大……风风雨雨、历久弥新。</div>
            <div className={NewsStyle.subTitle}>起航2007-2012</div>
            <div>2007年，山东顶联信息科技有限公司成立；</div>
            <div>提供高校信息化基础设施建设服务，以承建高新实验室学生机房为主。</div>
            <div className={NewsStyle.subTitle}>转型 2013-2018</div>
            <div>2013年开始提供高校网络项目投建及运营服务。</div>
            <div>2016年顶联信息新三板挂牌。</div>
            <div>2018年神州数码并购；同步研发“云顶”管理系统。</div>
            <div className={NewsStyle.subTitle}>升级 2019-2020</div>
            <div>2019年，业务拓展至全国范围，增加高校生活服务类项目投建运营服务；</div>
            <div>自研“云联”业务管理平台，与“云顶”管理系统打通，实现数据共享。</div>
            <div className={NewsStyle.subTitle}>生态 2021-</div>
            <div>布局校园支付领域，研发新一代校园一卡通；</div>
            <div>提供智慧校园解决方案及投建运营服务，自研多种物联网智能终端和10个产品与解决方案，致力打破各业务系统隔阂，实现校园场景下全量全要素数据连接。</div>
            <img src={'/img/news/news55.jpg'} />
            <div>生命不息，梦想不止。往日走过的每一步都是我们今后更大更强的基础。接下来，神州顶联将持续深化校园服务，站在行业制高点，秉持“以数据驱业务重构，极大提升师生应用体验”的理念，通过自身引领作用，为校园师生提供更优质的产品与服务，为智慧校园建设添砖加瓦！</div>
        </div>
    },
    {
        title: '华为&神州顶联智慧校园联合解决方案正式发布',
        img: '/img/news/news56.jpg',
        date: '2022-09-10',
        content: <div className={NewsStyle.news_container}>
            <div className={NewsStyle.title}>华为&神州顶联智慧校园联合解决方案正式发布</div>
            <div className={NewsStyle.date}>2022-09-10</div>
            <div>2022年9月9日，华为携手神州顶联智慧校园联合解决方案正式发布。华为中国政企合作伙伴解决方案支持与服务中心（以下简称“OpenLab”）部长赵雪山、中国政企合作伙伴解决方案支持与服务中心教育行业FSE陆法中、山东教育行业解决方案总监石福跃、神州顶联副总裁王红心、产品总监郭泉江、解决方案总监谢义晓等线上齐聚。</div>
            <div>教育数字化已经深入教学、科研、管理等各个领域，依靠数字化推动教育与信息技术的深度融合，驱动校务管理创新，成为教育数字化转型的必然趋势。</div>
            <div>华为中国政企合作伙伴解决方案支持与服务中心部长赵雪山表示，华为成立政企业务的十多年中，提供先进的ICT技术服务于教育信息化、数字化，除了为智慧校园建设提供融合网络、数据中心、HPC计算等产品，也联合行业伙伴“为技术找场景”，提供智慧教室、XR实训、智慧教学等系列校园领域的解决方案。华为一直坚持“有所为、有所不为”，坚持被集成的战略，支撑伙伴为教育行业客户提供完整的场景化解决方案与服务，这次联合发布解决方案是双方合作的第一步，后续要通过市场项目进一步完善、优化，形成受市场客户欢迎的联合方案，未来希望与神州顶联优势互补、持续耕耘，共同服务于教育行业客户，为推动教育数字化转型贡献力量。</div>
            <img src={'/img/news/news51.jpg'} />
            <div
                className={NewsStyle.img_description}>华为中国政企合作伙伴解决方案支持与服务中心部长&nbsp;&nbsp;&nbsp;&nbsp;赵雪山
            </div>
            <div>王红心谈及神州顶联15年发展，一直致力于校园信息化建设的探索、研究、建设和创新，如今成功转型升级为国内唯一一家产品创新研发+运营服务的融合体。与华为的合作，从初期华为云，到F5G全光网，再到共同推出华为OpenLab及神州顶联校园数字化联合解决方案,合作逐步深入。接下来，双方将在行业解决方案升级、用户服务体验升级、新技术融合、联合开拓市场等方面进一步持续加强，共建校园新生态。</div>
            <img src={'/img/news/news52.jpg'} />
            <div className={NewsStyle.img_description}>神州顶联副总裁&nbsp;&nbsp;&nbsp;&nbsp;王红心</div>
            <div>谢义晓对神州顶联进行企业介绍，并对智慧校园联合解决方案进行详细介绍。智慧校园联合解决方案是神州顶联以校园数字化建设需求为基础，将云联智慧校园APP与WeLink的连接，创建生态级云平台。通过合作，创造全新产品形态，为师生带来更多的便利，带给技术更强的生命力，赋能智慧校园生活。</div>
            <img src={'/img/news/news53.jpg'} />
            <div className={NewsStyle.img_description}>神州顶联解决方案总监&nbsp;&nbsp;&nbsp;&nbsp;谢义晓</div>
            <img src={'/img/news/news54.jpg'} />
            <div className={NewsStyle.img_description}>华为&神州顶联智慧校园联合方案架构</div>
            <div>华为&神州顶联智慧校园联合解决方案发布会圆满结束。本次合作，双方秉持相互学习、信息共享的态度，明确下一步发展路径，从市场、人员、解决方案、本地部署等进行连接，推动解决方案落地、推广及项目验证。与此同时，联合方案将在构建高效、适配、体验、开放的移动校园门户，形成全面感知校园环境、数据互融互通的校园网络环境，促进双方的应用开发效率上发挥重要作用。</div>
            <div>未来，双方将持续挖掘客户价值场景，加强业务场景创新合作，提升联合方案竞争力，扩大市场规模，创新驱动教育发展。</div>
        </div>
    },
    {
        title: '“数格知智慧”——神州顶联助推教育信息化发展',
        img: '/img/news/news50.jpg',
        date: '2022-08-19',
        content: <div className={NewsStyle.news_container}>
            <div className={NewsStyle.title}>“数格知智慧”——神州顶联助推教育信息化发展</div>
            <div className={NewsStyle.date}>2022-08-19</div>
            <div>为响应我国关于“加快推进教育现代化、建设教育强国”的部署，神州顶联以创新为内核，以引领为动力，于业务中构建认知、于服务中探索需求、于产品中建立矩阵、于行业中深度实践，以教育信息化作为加快推进教育现代化的重要抓手，持续推动智慧校园新发展、新提升，赋能校园信息化建设。</div>
            <div className={NewsStyle.subTitle}>一、构建认知，强化信息化顶层设计</div>
            <div>为全力推动教育信息化建设，我们的首要任务是在逻辑上厘清何为校园“信息化建设”。神州顶联以“五字布局”来强化认知，深刻理解校园信息化建设的涵义。</div>
            <div>以“数”据为业务源头——于海量数据中描绘出我们的业务对象。</div>
            <div>以“格”为数据标准——根据已知的业务对象数据出台业务规则，在零散化的数据中建立起区分业务对象的精确标准，明白怎么有针对性地服务业务对象。</div>
            <div>以“知”为业务逻辑——我们以数据治理为基础，全量全要素覆盖海量业务对象、构建态势感知、强化挖掘需求的能力，才能全方位击中业务对象的痛点。</div>
            <div>以“智”为行动指引——智慧化地分析、判断所得的数据结果，帮助业务对象做出校园信息化建设的正确决策。</div>
            <div>以“慧”为思路模型——时刻学习应对来自校园的丰富且不可枚举的数据信息，拓宽服务通道，优化服务保障。</div>
            <div>神州顶联通过强化高维的教育信息化顶层设计，以一终端多能、一数据多用、一网络通行、一平台多用、一校园智安的“五个一”理念涵养智慧校园建设。</div>
            <div className={NewsStyle.subTitle}>二、兼修自身，探索信息化建设需求‍</div>
            <div>如果说建立顶层设计是首要任务，那么摸清业务需求则是关键阶段。</div>
            <div>智慧校园的建设需求遵循由信息化、数字化、数智化，最终演变到智慧化的过程。若想协助校园推进教育体系的系统性、深层次变革，神州顶联首先需检验自身是否有能力通过智慧化服务给校园带来变革。</div>
            <div>神州顶联的解决方案，基于自身稳扎稳打的更新迭代演进背景，具备摸清用户需求处于智慧校园建设的哪个阶段的能力，根据用户不同需求，建立不同业务目标，以产品及解决方案作为核心驱动力，全面构建系统完善的智慧校园发展格局，系统性地推动教育信息化。</div>
            <img src={'/img/news/news47.jpg'} />
            <div className={NewsStyle.subTitle}>三、建立矩阵，布局全场景解决方案‍</div>
            <div>摸清业务，为的是能够全领域、多维度地布局业务场景。神州顶联用数据感知服务场景，精准识别用户需求，继而形成架构清晰的知识体系和平台，以全领域方案矩阵覆盖校园信息化建设，分别为智慧生活、智慧网络、一校通、智能门禁、智慧学工、数据治理、智慧后勤、新零售、校园私有云及应用、AI校园。</div>
            <div className={NewsStyle.subTitle}>四、深耕行业，推动信息化教育实践‍</div>
            <div>为帮助落实业务落地，我们需要保持深度实践，来积累面对行业、业务、客户的经验，在深耕行业的过程中成长与进步，成为行业专家，推动信息化教育实践。</div>
            <div>面对行业，我们持续学习，深化对教育信息化领域的理解，了解圈内的黑话、术语名词，快速打破行业门槛与壁垒。</div>
            <div>面对业务，我们深入探索，抓住校园用户的高频应用需求与数据的实时价值，发掘扩大新业务场景。</div>
            <div>面对客户，我们细致交互，大量提问。在双向互通过程中，既能帮助客户梳理业务逻辑，又能熟练掌握业务流程与细节，培养业务目标实现能力。</div>
            <div>智慧校园建设只有起点，没有终点。神州顶联坚持以创新驱动，助推新基建，为推动教育现代化做出贡献。</div>
        </div>
    },
    {
        title: '神州顶联牵手华为，召开山东省智慧校园研讨会',
        img: '/img/news/news46.jpg',
        date: '2022-07-28',
        content: <div className={NewsStyle.news_container}>
            <div className={NewsStyle.title}>神州顶联牵手华为，召开山东省智慧校园研讨会</div>
            <div className={NewsStyle.date}>2022-07-28</div>
            <div>2022年7月28日，神州顶联联合华为技术有限公司山东代表处政企业务部共同组织的“智能重构教育创新联接未来”山东省智慧校园研讨会，在济南凯宾斯基酒店盛大召开。</div>
            <div>山东省电化教育馆副馆长周长笃、济南教育信息化协会会长李政、华为政企光领域总裁金志国、华为中国政企教育医疗教育系统部总经理郭金亮、华为政企中国区光网络解决方案总监吴家华、华为山东政企合作伙伴发展与管理部部长朱楠、山东蓝贝思特教装集团股份有限公司董事长李清波、神州顶联董事长刘国梁、神州顶联副总裁兼产品事业部总经理王红心，以及来自全省90余所高校、区县教育局信息化专家等，共超百位嘉宾出席了本次会议。</div>
            <img src={'/img/news/news39.jpg'} />
            <div className={NewsStyle.img_description}>活动现场</div>
            <img src={'/img/news/news40.jpg'} />
            <div className={NewsStyle.img_description}>华为政企光领域总裁&nbsp;&nbsp;&nbsp;&nbsp;金志国</div>
            <div>会议伊始，华为政企光领域总裁金志国、神州顶联董事长刘国梁、华为中国政企教育医疗教育系统部总经理郭金亮分别发表致辞对参会的嘉宾表示欢迎。</div>
            <div>金志国表示，F5G全光网络方案在教育行业为在线教育、实时课堂等各类教育场景应用铺垫了最为稳固和高速的通道，为高校信息化建设提供了快速部署、一纤高可靠承载的建网新体验，可有效推动教育教学的高质量发展。此次与神州顶联的合作，将深度推进华为F5G全光网解决方案在智慧校园中建设的可能性。</div>
            <img src={'/img/news/news41.jpg'} />
            <div className={NewsStyle.img_description}>神州顶联董事长&nbsp;&nbsp;&nbsp;&nbsp;刘国梁</div>
            <div>刘国梁简要介绍了校园信息化建设的历程，并表示神州顶联十五年紧跟校园发展趋势，将“以数据驱动业务重构，极大优化师生应用体验”的建设理念，作为业务应用创新的出发点，以云原生和数字原生作为技术架构，和校方一道将校园业务应用重做一遍，推进实现校园数字化转型。同时表示，神州顶联将加强与华为在校园多场景软、硬产品的联合开发，共同赋能校园信息化建设，加速推动中国校园智能化跨越式发展。</div>
            <img src={'/img/news/news42.jpg'} />
            <div
                className={NewsStyle.img_description}>华为中国政企教育医疗教育系统部总经理&nbsp;&nbsp;&nbsp;&nbsp;郭金亮
            </div>
            <div>郭金亮指出，华为一直坚持“有所为、有所不为”，坚持被集成的战略，联合业界伙伴一起为教育行业客户提供整合的方案与服务。将华为在5G、F5G全光、云计算、数据存储、机器视觉、智能协作等领域的技术优势，与神州顶联在高校网络领域的丰富经验与实践相结合，优势互补，共同服务于教育行业客户，促进中国教育数字化转型的发展贡献更多的力量。</div>
            <img src={'/img/news/news43.jpg'} />
            <div className={NewsStyle.img_description}>华为政企中国区光网络解决方案总监&nbsp;&nbsp;&nbsp;&nbsp;吴家华
            </div>
            <div>吴家华就华为全光网络解决方案做了详细介绍。华为全光网络解决方案以海量带宽、广域覆盖、绿色降耗为三大优势，将全光网络方案适配校园场景，创造高速、融合、绿色、便捷、智能、泛在的智慧校园网络。F5G全光校园网的应用，不仅能加速校园新基建，也有效助力校园碳中和，让“教育更智慧，教学更智能”。</div>
            <img src={'/img/news/news44.jpg'} />
            <div className={NewsStyle.img_description}>神州顶联副总裁兼产品事业部总经理&nbsp;&nbsp;&nbsp;&nbsp;王红心
            </div>
            <div>王红心以“数化万物，聚势共生”为题，详细介绍了神州顶联数字化校园产品解决方案。王红心指出，智慧校园建设“只有起点没有终点”，新技术不断涌现，新需求不断升级，智慧校园的建设也在不断地更新。神州顶联产品设计以数据为驱动力，将业务内容数据化，将校园的教学、科研、管理、服务与校园信息资源、应用系统进行整合，实现数据全融合、状态全可视、业务全可管、事件全可控，搭建校园业务生态，实现万物互联。</div>
            <img src={'/img/news/news45.jpg'} />
            <div className={NewsStyle.img_description}>神州顶联与华为签约仪式</div>
            <div>最后，双方签署了全面合作协议。接下来，双方将优势互补，合作共赢，共同服务于教育行业客户，持续赋能校园数字化建设、共创新价值。</div>
            <div>教育是立国之本、强国之基。未来，神州顶联将携手更多行业优秀伙伴，以新一代信息技术与教育教学深度融合为主线，以应用驱动和机制创新为重点，将更具智慧化、智能化的产品与服务带入校园，加快推进教育数字化转型，助推教育高质量发展。</div>
        </div>
    },
    {
        title: '神州顶联再度牵手济南工程职业技术学院，促进校企合作多元发展',
        img: '/img/news/news34.jpg',
        date: '2022-07-19',
        content: <div className={NewsStyle.news_container}>
            <div className={NewsStyle.title}>神州顶联再度牵手济南工程职业技术学院，促进校企合作多元发展</div>
            <div className={NewsStyle.date}>2022-07-19</div>
            <div>2022年7月19日，济南工程职业技术学院党委书记张慧青、副校长徐运国、信息工程学院院长任清华、副院长王谦、对外合作交流处处长潘辰一行到访神州顶联。华为中国政企教育医疗教育系统部总经理郭金亮、华为山东政企济南教育负责人闫旭，神州顶联董事长刘国梁、分管大客户副总裁张玉及其他成员参加了本次会谈，双方就进一步深化产教融合展开全面交流。</div>
            <img src={'/img/news/news35.jpg'} />
            <div>济南工程职业技术学院始创于2003年，是经山东省人民政府批准的全日制普通高等院校，山东省首批特色名校建设项目优秀单位。张慧青表示，为响应国家教育信息化要求，学校一直积极推进校企合作，推进智慧校园建设。当下校园面临信息及应用不畅通的现象，需加强生活服务、校园管理、后勤系统整体建设。如何让“师生少跑路，数据多跑腿”，优化业务流程，为教学、科研和管理提供更便捷的应用和服务成为校方重点关注内容。</div>
            <img src={'/img/news/news36.jpg'} />
            <div>刘国梁向校方领导详细介绍了神州顶联的发展历程，并表示在数据流转和智能信息领域将和华为展开更深入的合作，重点解读神州顶联校园数字化转型的解决方案。他指出，教育数字化是智慧校园建设的前提，未来10年，基于数据产生的校园服务与应用是公司业务重点，进一步完善校园基础设施建设、信息化公共平台，围绕校园智慧生活、智慧网络、智慧门禁、新零售、智慧学工等多方面开展，以支撑学校教学科研创新和体制机制改革。</div>
            <img src={'/img/news/news37.jpg'} />
            <div>郭金亮详细介绍华为教育业务，他表示华为以技术创新促进教育数字化转型，将在信息技术与教育教学场景深度融合和产教融合两个方面持续发力。华为&神州顶联将在山东持续深耕和推广智慧校园联合解决方案，致力使校园更智慧，使教育更智能。</div>
            <img src={'/img/news/news38.jpg'} />
            <div>校方对于华为&神州顶联校园信息化建设思路给予了高度肯定，并表示可以围绕产教融合的新思路，进行多方面合作。结合神州顶联产品技术优势，融合华为智能硬件，与学校教育信息化相结合，三方优势互补，深化产业合作，积极推动济南工程职业技术学院信息化转型和智慧校园建设。</div>
        </div>
    },
    {
        title: '智能重构教育，创新联接未来——华为牵手神州顶联，共筑全新智慧校园',
        img: '/img/news/news33.jpg',
        date: '2022-07-19',
        content: <div className={NewsStyle.news_container}>
            <div className={NewsStyle.title}>智能重构教育，创新联接未来——华为牵手神州顶联，共筑全新智慧校园</div>
            <div className={NewsStyle.date}>2022-07-19</div>
            <img src={'/img/news/news32.jpg'} />
        </div>
    },
    {
        title: '砺戈秣马·踔厉奋发——神州顶联2022年中总结大会圆满结束',
        img: '/img/news/news31.jpg',
        date: '2022-07-16',
        content: <div className={NewsStyle.news_container}>
            <div className={NewsStyle.title}>砺戈秣马·踔厉奋发——神州顶联2022年中总结大会圆满结束</div>
            <div className={NewsStyle.date}>2022-07-16</div>
            <div>2022年7月16日，神州顶联科技有限公司在济南总部召开了2022年年中总结会议。公司全体人员及各分公司团队200余人参加了本次会议。</div>
            <img src={'/img/news/news27.jpeg'} />
            <div>会议伊始，神州顶联总经理刘国梁深入、客观、全面分析了当下行业趋势及公司的组织战略。当下，公司业务主要分为产品销售与投建运营两大类，形成产品解决方案能力、投建运营能力、精进成长能力的三大核心能力。实现可持续、良性循环的商业运营模式，以及较强的抗风险能力。同时，明确下一步工作措施，布局全省、全国智慧校园投建运营战略，为圆满完成2022年各项工作夯实基础。</div>
            <img src={'/img/news/news28.jpeg'} />
            <div>神州顶联副总经理王红心对顶联的产品和解决方案整体设计思路和优势做了详细介绍。产品以“创新”与“引领”为关键要素，创新新理念、新技术、新场景，引领需求、趋势、发展。将校园内产生的丰富的数据，构建态势感知的模型，并依此来解决校园内各类问题。基于对数据理解，“五个一”的顶层设计理念涵盖产品组织的方方面面。未来，顶联将继续深化“一终端多能，一数据多用，一网络通行，一平台通办、一校园智安”的“五个一”校园数据治理模式，不断推动智慧校园建设。</div>
            <img src={'/img/news/news29.jpeg'} />
            <div>各部门负责人逐一上台进行了团队介绍。通过部门的文化展现，各部门之间有了更加深入的了解。全员的凝心聚力，坚定不移扎根校园智慧化建设，服务于校园生活管理，赋予更大的责任，大胆尝试，才能实现公司的战略目标，建设多维空间全联接、实时数据全贯通的智慧校园新生态。</div>
            <img src={'/img/news/news30.jpeg'} />
            <div>砺戈秣马，踔厉奋发。在2022开篇之际，我们踏着坚实的步伐，稳扎稳打每一步；2022下半年，我们仍将以破竹之势乘风破浪，创造属于我们更好的明天！</div>
        </div>
    },
    {
        title: '神州顶联科技有限公司产教融合、校企合作三年发展规划',
        img: '/img/news/news26.jpg',
        date: '2022-06-08',
        content: <div className={NewsStyle.news_container}>
            <div className={NewsStyle.title}>神州顶联科技有限公司产教融合、校企合作三年发展规划</div>
            <div className={NewsStyle.date}>2022-06-08</div>
            <div className={NewsStyle.subhead}>一、发展基础</div>
            <div>神州顶联科技有限公司成立于2007年，神州数码集团股份有限公司的教育板块子公司。总部位于山东济南，现设有重庆、河北、吉林、安徽、山西、陕西6家子分公司。依托软硬件研发、运营服务一体化生态网络，将互联网与教育、服务深度融合，提供智慧校园整体解决方案与投建运营服务。</div>
            <div>神州顶联深入校园信息化需求，围绕“教、学、研、管、服、评”等校园全场景、全流程，结合自研智能感知终端，打造了“智慧后勤、智慧学工、AI
                校园、智能门禁、校园私有云及应用、全校一张网、数据中台”八大解决方案，形成多维空间全联接、实时数据全贯通的智慧校园新生态。
            </div>
            <div>经过15年沉淀与探索，已在全国范围内建立完善服务网络，与中国科技大学、华南理工大学、山东师范大学等全国300余所院校达成深度合作，服务用户超200万。</div>
            <div>未来，神州顶联将继续深化“一终端多能，一数据多用，一网络通行，一平台通办、一校园智安”的“五个一”校园数据治理模式，以数据驱动校园业务重构，极大提升师生应用和服务体验，加速推动中国校园智能化跨越式发展。</div>
            <div className={NewsStyle.subhead}>二、指导思想</div>
            <div>为深入贯彻落实习近平总书记在十九届中央政治局第三十四次集体学习时要求：“充分发挥海量数据和丰富应用场景优势，促进数字技术与实体经济深度融合，赋能传统产业转型升级，催生新产业新业态新模式，不断做强做优做大我国数字经济”。进一步推动和深化产教融合、校企合作，充分发挥企业在技术技能人才培养和人力资源开发中的重要主体作用，强化产教融合型企业的带动引领示范作用，按照国家发展改革委和教育部关于《建设产教融合型企业实施办法(试行)》，以及山东省政府出台《山东省支持数字经济发展的意见》《现代产业学院建设指南（试行）》要求等文件精神，公司制定以下产教融合、校企合作三年发展规划。</div>
            <div className={NewsStyle.subhead}>三、发展思路</div>
            <div>未来三年的时间里，公司将进一步巩固以往校企合作所取得的成果，按照“资源共享，优势互补，责任同担，利益共享”的原则，加快打造具有国际水平的现代技工教育体系，培养更多具有良好专业知识、实际操作技能和职业素养的高素质、高技能的应用型人才，促进技工院校深化教育改革、提升教育培训质量,结合各产业转移升级对技能人才的需求，校企双方开展多层次、多形式的合作，建立稳定的校企合作关系，促进企业发展、提升企业的竞争力。</div>
            <div className={NewsStyle.subhead}>四、合作院校选择</div>
            <div>职业院校为我司校企合作优先选择对象，合作院校包括山东农业工程学院、山东建筑大学、济南职业学院、山东政法学院、德州职业技术学院等。</div>
            <div className={NewsStyle.subhead}>五、合作规划</div>
            <div>依托高校教育教学优势和神州顶联企业资源优势，采用共建共管共享方式，打造集人才、技术、产业融合的数字产业基地，为更好的服务于区域人才引进、本地化就业及产业发展，助推区域产业，为更好推进应用型本科高校建设和提升企业科技附加值探索提供可复制、可推广的新模式。</div>
            <div>以“岗位技能”为培养标准，以“项目实战”为培养方式，通过人才价值链打通“地方院校、产业、区域发展”的供需壁垒，围绕“一学院”“一中心”建设展开合作。</div>
            <div className={NewsStyle.subTitle}>（一）一学院：培养数字技术应用型人才</div>
            <div>1.校企专业共建。以电子类、通讯类、物联网、大数据、数字媒体等为核心的专业群建设，共建数字技术产业学院，共同培养数字技术方向应用型人才。</div>
            <div>2.课程体系共建。依托神州顶联丰富的项目经验和深厚的前沿技术能力，深度参与教材编制和课程建设，与学校共同成立研发、技术、运营团队的师资团队，设计课程体系、优化课程结构，加快课程教学内容迭代。</div>
            <div>3.校内实战基地。在校内建立人才、技术、产业相互融合的数字技术产业基地，将企业生产经营标准和环境引入教学过程。实现课程内容与前沿技术对接、教学过程与工作过程对接、校园与产业园对接，缩短教育教学与生产实践的距离，推进人才培养与项目建设耦合发展。将技术赋能人才，人才赋能产业，产业赋能教育，深化产教融合。</div>
            <div className={NewsStyle.subTitle}>（二）一中心：技术研究、成果应用、公共实训融合发展</div>
            <div>1.科研项目开发。满足院校对接市场前沿技术的科研需求，围绕数字技术领域，引入信息化、智能化、大数据、物联网等元素，整合双方资源，打造数据收集、治理、呈现的全处理应用链条，作为学校产品研发、创新中心、科研中心及成果转化中心。</div>
            <div>2.科研成果申报及应用。校企联合申报省级、国家级重大课题项目，并通过科研力量辅助专业建设。同时，服务于区域内相关企业，技术平台通过企业付费、政府补贴的方式为对数字经济化有迫切需求的企业提供服务，满足企业技术升级的业务需求。</div>
            <div>3.公共实训服务。以项目实战为核心，在满足本校学生教育实训的基础上，面向社会开展公共教育实训服务，为区域内相关行业企业的从业人员、待就业人员及其他有需求的人员提供现有方向的教育和实训，面向其他院校、社会开展公共教育实训服务。</div>
            <div className={NewsStyle.subhead}>六、预期目标</div>
            <div>（一）通过“一学院”，参与实战项目。神州顶联与院校共同承接区域内数智化校园、企业转型服务，满足当地企业数字服务的需求，带动当地数字经济产业、数字服务产业的发展的同时，增加学校收益。同时，神州顶联引进高层次专业数字技术人才，加强专业赋能，提升产业学院水平；安排实战型项目经理，培养并带领产业学院团队及学生，共同完成数字化产业项目。</div>
            <div>（二）通过“一中心”，联合申报项目。神州顶联与院校就数字技术产业科研项目，积极发挥专业能力相互协作，联合申报项目，加强科研成果转化，获取项目经费及成果应用收益。</div>
            <div>（三）实体产业带动教学价值。神州顶联充分发挥行业头部企业的带动作用，提供企业数智化校园建设项目，帮助学生将所学应用到数智化校园的建设中，为数智化校园的建设贡献力量。</div>
            <div>（四）本地化就业及培养价值。通过产业学院产业集群发展，为区域培养多层次技术人才及人才引进。产业汇集培训、人工智能、大数据、云服务、物联网等研发加工企业，产业链带动就业链，就业链促进产业链，提供近千个直接或间接的就业机会。</div>
        </div>
    },
    {
        title: '神州顶联版图再拓！山西公司盛大启幕',
        img: '/img/news/news20.jpg',
        date: '2022-03-29',
        content: <div className={NewsStyle.news_container}>
            <div className={NewsStyle.title}>神州顶联版图再拓！山西公司盛大启幕</div>
            <div className={NewsStyle.date}>2022-03-29</div>
            <div>2022年3月29日，神州顶联科技（山西）有限公司正式开业，成为神州顶联在全国成立的第5家分公司。</div>
            <img src={'/img/news/news20.jpg'} />
            <div className={NewsStyle.picture}><img src={'/img/news/news21.jpg'} /><img src={'/img/news/news22.jpg'} />
            </div>
            <div className={NewsStyle.explain}>嘉宾签到现场</div>
            <div>山西省人大常委、山西综改区、职教协会、高校后勤协会相关负责人，山西大学、太原理工大学、太原科技大学、山西师范大学、山西传媒学院、山西工程职业学院、太原学院、山西林业职业技术学院等相关部门负责人，华为、新华三集团、深信服科技等设备厂商，三大通信运营商及数十家渠道商，共30余人会聚山西太原，共同见证神州顶联发展进程中的又一重要里程碑。</div>
            <div style={{
                fontSize: 14,
                color: '#666'
            }}>当天参加开业仪式的还有：杭州海康威视数字技术股份有限公司、深圳市凯路创新科技有限公司、深圳市一合智能科技有限公司、广东优溢净水科技有限公司、青岛科源美商用设备有限公司、山西恒文晟科技有限公司、太原智林信息技术股份有限公司
                、山西邦源科技有限公司、山西直通信息技术有限公司
                、山西三叶虫信息技术股份有限公司、山西易智融网络科技有限公司、山西信轩科技有限公司、山西诚尚科技有限公司等相关部门负责人。
            </div>
            <img src={'/img/news/news23.png'} />
            <div>响应疫情防控要求，神州顶联科技有限公司董事长刘国梁在山东总部通过视频连线，与各级参会领导、神州顶联山西公司全体员工共同见证开业时刻。并表示，山西是公司重要战略市场，总部将开放更多优惠政策，全力以赴助力山西市场发展。</div>
            <div>山西公司的设立是神州顶联深耕山西市场、提升当地服务水准的重要举措。作为开拓华北区域的主要战场，将为华北大地各院校享受神州顶联本地化服务提供充分便利条件。</div>
            <img src={'/img/news/news24.jpg'} />
            <div>活动最后，政府、校方与各级代表共同为开业剪彩。</div>
            <img src={'/img/news/news25.jpg'} />
            <div>新时代对高等教育发展提出新要求。近年来，山西频频发力加快信息化建设，探索教育生态蝶变新路径。山西公司将持续深化区域服务，整合优质生态资源，加大资金投入力度，助力山西高校破局腾飞，为山西教育现代化转型发展提供强大智力支撑，为建设山西重要人才中心和创新高地增值赋能。</div>
            <div style={{ height: 30 }} />
        </div>,
    },
    {
        title: '“顶联智慧感知物联存算一体芯片模组”项目，实力摘得“双创金钥匙”',
        img: '/img/news/news10.jpg',
        date: '2022年3月1日',
        content: <div className={NewsStyle.news_container}>
            <div className={NewsStyle.title}>“顶联智慧感知物联存算一体芯片模组”项目<br />实力摘得“双创金钥匙”</div>
            <div className={NewsStyle.date}>2022年3月1日</div>
            <img src={'/img/news/news10.jpg'} />
            <div>2022年3月1日，国家大众创业万众创新示范基地暨山东大学创新创业学院总部揭牌活动成功举办。</div>
            <img src={'/img/news/news11.jpg'} />
            <div>山东大学常务副校长王琪珑、山东大学副校长吴臻、历城区委书记张军、原区人大常委会主任孙德顺、济南市科技局副局长贾文涛、历城区副区长耿汝年、副区长杨克建、山东大学和历城区相关部门负责人、部分企业代表出席活动。历城区副区长耿汝年主持活动。</div>
            <img src={'/img/news/news12.jpg'} />
            <div
                className={NewsStyle.explain}>【山东大学常务副校长王琪珑、历城区委书记张军为“国家大众创业万众创新示范基地”揭牌】
            </div>
            <img src={'/img/news/news13.jpg'} />
            <div
                className={NewsStyle.explain}>【山东大学副校长吴臻、原历城区人大常委会主任孙德顺为“山东大学创新创业学院总部”揭牌】
            </div>
            <div style={{ 'height': 0 }}></div>
            <div>揭牌现场，山东大学常务副校长王琪珑、历城区委书记张军、济南市科技局副局长贾文涛，分别为顶联智慧感知物联存算一体芯片模组、重大装备智能流体动力与电控系统、云飞测控装备与数字孪生敏捷处理系统等14个荣获“双创金钥匙”的项目颁发荣誉，神州顶联位列其一。</div>
            <img src={"/img/news/news14.jpg"} alt="" />
            <div className={NewsStyle.explain}>【神州顶联科技有限公司董事长刘国梁上台领取“金钥匙”】</div>
            <div style={{ 'height': 0 }}></div>
            <div>神州顶联科技有限公司瞄准解决集成电路“卡脖子”难题，与山东大学共同研发适配高校场景mcu和存算一体的两款芯片。<br /><br />
                其中，MCU芯片是将计算机的CPU、RAM、ROM、定时计数器和多种I/O接口集成在一片芯片上，形成芯片级的计算机，为不同的应用场合做不同组合控制。<br /><br />
                存算一体芯片将传统以计算为中心的架构转变为以数据为中心的架构，其直接利用存储器进行数据处理，从而把数据存储与计算融合在同一芯片中，极大提高计算并行度与能量效率。<br /><br />
                芯片是信息技术的核心基础也是人工智能的重要基础之一。此举将彻底打破动力系统的技术壁垒，成功将核心技术掌握在自己手中，促进高校全面智能化发展。同时，培养、吸引产业人才，汇聚海内外优秀人才
                ，打造集成电路人才培养高地，搭建人才引进桥梁，助推产业发展。<br /><br />
                此次项目合作，以基地为平台，将山大综合智力资源与顶联科技资源紧密结合，充分释放“名企+名校”价值潜力。通过开展创新创业活动，培养综合素质高、实践创新能力强的世界一流人才，更好响应从科技大国迈向科技强国的号召，为国家科技创新发展贡献力量。
            </div>
        </div>,
    },
    {
        title: '神州顶联2022年业务启动大会圆满召开',
        img: '/img/nianhui/news00.png',
        date: '2021-12-18',
        content: <div className={NewsStyle.news_container}>
            <div className={NewsStyle.title}>神州顶联2022年业务启动大会圆满召开</div>
            <div className={NewsStyle.date}>2021-12-18</div>
            <img src={'/img/nianhui/news00.png'} />
            <div>近日，神州顶联2022年业务启动大会隆重召开，公司全体员工及全国各地合资公司代表等200余人共同参会。创始人兼CEO刘国梁同大家一起回顾分享十五年的发展历程，并对公司战略规划、商业模式及产品规划进行解读。</div>
            <img src={'/img/nianhui/news01.png'} />
            <div>十五年，神州顶联始终聚焦高校信息化建设领域，将大量精力、成本投入产品研发，苦炼内功，稳扎稳打。依托15年经验积累与沉淀，于群雄中硬核突围，占领鳌头。<br />
            </div>
            <div>接下来，神州顶联将进一步加强顶层设计的核心指引能力，做实数据原生能力。业务系统+硬件技术双轮驱动，继续缩短技术更迭周期，灵活响应高校信息化变革需求，保持持续创新力。</div>
            <img src={'/img/nianhui/news02.png'} />
            <div>数据是推动高校信息化建设的关键。基于业务场景，副总经理王红心对平台的功能，及核心数据支撑能力进行了深度解析。同时宣布了新一年技术研发战略规划。2022年，神州顶联将加大研发投入，持续强化技术平台创新能力，通过全量全要素实时数据联接，助力高校数智化转型升级。<br />
            </div>
            <div>秣马厉兵，蓄势待发。新的一年，基于2022年全新组织架构，神州顶联将持续提升对业务的响应速度。立足新发展阶段，洞察校园生活新需求，探索新应用，构建新格局。继续以自主研发安全可控的数字化解决方案、以丰富的数字化转型落地经验，赋能全国高校数智校园建设，推动高校数智化高质量发展。</div>
            <div style={{ height: 30 }} />
        </div>,
    },
];

export const PRIVACY = "本《隐私政策》（下称“本政策/本隐私政策”）仅适用于神州顶联科技有限公司的菁彩校园产品或服务，旨在向您说明，在使用菁彩校园时，您应了解的内容：\n" +
    "\n" +
    "一、信息收集\n" +
    "\n" +
    "二、信息共享、转让及公开披露\n" +
    "\n" +
    "三、信息安全与保护\n" +
    "\n" +
    "四、用户个人信息管理\n" +
    "\n" +
    "五、未成年人信息保护\n" +
    "\n" +
    "六、如何联系我们\n" +
    "\n" +
    "七、隐私政策的更新\n" +
    "\n" +
    "八、如何联系我们\n" +
    "\n" +
    "您的信任对我们至关重要，我们深知个人信息对您的重要性。我们将按照法律法规要求，在合理、必要的范围内，向您收集个人信息。我们竭尽全力通过合理有效的信息安全技术和管理流程，采取相应的安全保护措施，防止您的信息泄露、损毁或丢失。鉴于此，菁彩校园服务提供者（神州顶联科技有限公司(，以下或简称“我们”或“菁彩校园”）制定《隐私政策》。\n" +
    "\n" +
    "除某些特定服务外，我们所有的服务均适用《隐私政策》。特定服务将适用特定的隐私政策或协议。需要特别说明的是，本政策不适用于第三方向您提供的服务，例如通过菁彩校园跳转到第三方网站、客户端或程序，您向该第三方服务商提供的个人信息不适用本政策。\n" +
    "\n" +
    "在使用菁彩校园各项产品或服务前，请您务必仔细阅读并透彻理解本政策，特别是以粗体/粗体下划线标识的条款，您应重点阅读，在确认充分理解并同意后再开始使用。如对本政策内容有任何疑问、意见或建议，您可通过菁彩校园提供的各种联系方式与我们联系。\n" +
    "\n" +
    "一、信息收集\n" +
    "\n" +
    "为了更好地为您提供服务，菁彩校园将遵循“合理、相关、必要”原则，且以您同意为前提收集信息，不会收集法律法规禁止收集的信息。\n" +
    "\n" +
    "（一）您须授权我们收集和使用您个人信息的情形\n" +
    "\n" +
    "1、为了向您提供我们产品/服务的基本功能，您需要授权我们收集、使用必要信息的情形，如您拒绝提供必要信息，您将无法正常使用我们的产品/服务；\n" +
    "\n" +
    "2、为了向您提供我们产品/服务的拓展功能，您可以选择授权我们收集、使用信息的情形，如您拒绝提供前述信息，您将无法正常使用相关附加功能或无法实现我们拟达到的功能效果，但并不会影响您正常使用我们产品/服务的基本功能。\n" +
    "\n" +
    "①帮助您成为我们的用户\n" +
    "\n" +
    "为遵守法律法规的要求，以及向您提供更便捷的服务，在您注册成为菁彩校园用户时，您需要至少提供手机号码以创建菁彩校园账号，并完善相关的网络身份识别信息（如头像、昵称及登录密码等）；如果您仅需使用浏览、搜索等功能，您无需注册成\n" +
    "为我们的用户以及提供上述信息。\n" +
    "\n" +
    "②向您提供基于地理位置的信息展示\n" +
    "\n" +
    "a）为了提升您的使用体验，我们可以通过使用您所在位置向您提供相关服务；\n" +
    "b）日志信息。当您使用我们提供的产品/服务时，我们会收集您的浏览、搜索、点击、收藏、关注、分享、发布等信息并作为有关网络日志进行保存，其中包括您的 IP 地址、浏览器的类型、使用的语言、操作系统的版本、访问的日期和时间、电信运营商、网络请求等。\n" +
    "c）您可以在手机的系统设置中关闭定位服务停止我们对您所在位置信息的收集，但可能因此无法使用我们基于地理位置为您提供的服务，或者无法达到相关服务的预期效果。\n" +
    "\n" +
    "③帮助您完成支付\n" +
    "\n" +
    "您在菁彩校园上进行订单支付时，您可以选择菁彩校园及其关联方（如菁彩校园支付）或者菁彩校园的第三方合作机构（如微信支付、QQ 钱包、Apple Pay、支付宝等合作方）所提供的服务进行支付。我们需要收集您的菁彩校园订单信息、对账信息及其他法律要求的必要信息并与这些合作机构共享，以确认您的支付指令并帮助您完成支付。如您使用的软件版本和硬件设备支持指纹或面容 ID 支付，您还可以在支付设置中选择开启或关闭对应的指纹或面容 ID 支付功能，我们会在您支付过程中提供相应选项，同时您也可以选择密码、短信验证码等其他方式支付。支付的过程中，我们仅收集验证的结果，而不会直接收集您的指纹或面容 ID 信息。\n" +
    "\n" +
    "④安全保障\n" +
    "\n" +
    "为了保障您的帐号安全、交易安全以及系统运行安全，满足法律法规和我们协议规则的相关要求，在您使用我们的产品/服务过程中，通过客户端程序嵌入友盟SDK我们会获取您的设备信息，包括您使用设备属性、连接和状态信息，例如设备型号、唯一设备标识符（IME/AndroidID/IDFA/OPENUDID/\n" +
    "GUID、SIM卡IMSI信息等）、设备Mac地址、电信运营商信息、版本号、运行情况、使用情况及频率、监控产品崩溃率及错误信息、及时修复产品故障，优化客户端产品提高用户使用体验，同时保障您的帐号安全。\n" +
    "\n" +
    "⑤售后服务\n" +
    "\n" +
    "当您与我们客服取得联系时，我们可能会查询到您的相关个人信息以便您适当的帮助和处理。\n" +
    "\n" +
    " <h2>（二）您可以选择授权我们权限和使用个人信息的场景\n" +
    "\n" +
    "1、基于位置信息的搜索附近设备功能：您通过系统授权开启移动设备的定位功能并使用基于位置提供的服务时，我们会收集和使用您的位置信息以便为您推荐周边的自助服务等等。我们收集的地理位置的信息包括GPS或WLAN的方式，您可以通过关闭定位功能，停止对您的地理位置信息的收集。\n" +
    "\n" +
    "2、基于摄像头（相机）的功能：您可以选择开启系统的相机权限，通过使用二维码、条码的扫描等功能授权菁彩校园访问您的相机。若拒绝授权，您将无法享受上述服务，但不影响您使用本产品的其他功能。\n" +
    "\n" +
    "3、基于调用拨打电话的快速呼叫功能：您可以使用该功能来方便您联系客服。\n" +
    "\n" +
    "4、基于读写设备存储功能：能够通过写入缓存的方式存储产品运行的服务日志信息，以及存储设备uuid用来提供设备唯一性验证，防止作弊功能，我们的App会向您申请读写手机存储权限。若拒绝授权，您将无法享受上述服务，但不影响您使用本产品的其他功能。\n" +
    "\n" +
    "5、基于蓝牙的操作功能：启动蓝牙设备等操作需要开启蓝牙，否则可能无法提供服务；\n" +
    "\n" +
    "6、手机识别码（IDFA/IMEI/OAID）：作为手机唯一标识，用来统计数据，经过统计后的数据，可让开发者优化程序与系统兼容适配;\n" +
    "\n" +
    "7、软件安装列表读取： 精准化服务和快捷登陆。\n" +
    "\n" +
    "8、手机通知栏权限：为保障消息的推送和服务的及时性，我们的App会向您申请手机通知栏权限。若拒绝授权，您将无法享受上述服务，但不影响您使用本产品的其他功能。\n" +
    "\n" +
    "9、麦克风权限：为了给您提供录音、语音识别等服务，并确保其可用性，我们的App会向您申请麦克风权限。若拒绝授权，您将无法享受上述服务，但不影响您使用本产品的其他功能。\n" +
    "\n" +
    "10、设备信息权限：为了给您提供防止恶意登录、防作弊检测等功能，并确保其可用性，我们的App会向您申请获取设备信息权限。若拒绝授权，您将无法享受上述服务，但不影响您使用本产品的其他功能。\n" +
    "\n" +
    "请您注意，您开启任意权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。</h2>  \n" +
    "\n" +

    "<h2>（三）面部数据的应用、使用、披露、共享和保留\n" +
    " 在使用消费场景或通行场景中，我们经过您同意后收集的是您的人脸照片，以进行活体校验及身份核验的功能。\n" +
    " 1、收集的面部数据：我们的应用可能会收集面部识别数据，包括但不限于面部图像、面部特征点以及可能用于身份验证的生物识别信息。\n" +
    " 2、面部数据的使用：具体来说，这些数据可能用于\n" +
    "  ● 用户身份验证和账户安全。\n" +
    " 3、数据共享与存储：我们不会在未经用户明确同意的情况下与第三方共享面部数据。收集到的面部数据将存储在我们的安全服务器上，我们采取行业标准的安全措施来保护数据不被未授权访问、披露、更改或破坏。\n" +
    " 4、数据保留期限：我们仅会在提供服务所需的时间内保留面部数据。一旦数据不再需要用于其收集目的，我们将按照法律法规和行业最佳实践进行数据的删除或匿名化处理。你也可以通过注销账户用来删除您的人脸信息。</h2> \n" +
    "\n" +

    "（四）集成SDK使用您个人信息的情形\n" +
    "为保障我们客户端的稳定运行、功能实现，使您能够使用和享受更多的服务及功能，我们的应用中会嵌入授权合作伙伴的SDK或其他类似的应用程序，此时，第三方SDK服务商可能会收集您的相关信息，具体如下：\n" +
    "\n" +
    "SDK名称：极光推送 SDK\n" +
    "第三方主体：深圳市和讯华谷信息技术有限公司\n" +
    "SDK 用途：为 APP 用户提供信息推送服务\n" +
    "处理个人信息类型：设备标识符（包括IMEI、IDFA、AndroidID、GAID、MAC、OAID、IMSI、MEID、UAID）、设备硬件信息（包括设备型号、设备屏幕分辨率、设备硬件制造商、设备产品名称）、操作系统信息（包括操作系统版本、系统名称、系统语言）、网络信息（包括网络类型、运营商名称、基站信息、IP地址、WiFi信息、SSID、BSSID）、推送信息日志、精确位置信息、软件列表信息（包括软件列表及软件运行列表信息）。\n" +
    "数据处理方式：通过去标识化、加密传输及其他安全方式\n" +
    "极光隐私权政策链接：https://www.jiguang.cn/license/privacy\n" +
    "官网链接：https://www.jiguang.cn/\n" +
    "\n" +
    "SDK名称： 鲸鸿动能SDK\n" +
    "第三方主体：华为软件技术有限公司\n" +
    "收集个人信息类型：设备标识符（OAID、Android ID）、设备信息（设备型号、设备硬件信息、操作系统、系统设置、设备使用信息）、应用信息、传感器信息（陀螺仪、加速度计、旋转矢量传感器、磁力计、气压计）、网络信息、运营商信息、IP地址、Wi-Fi信息（Wi-Fi状态、Wi-Fi 参数与Wi-Fi列表）、位置信息（如精确位置信息（可选，不存储）与Wi-Fi、蓝牙列表等粗略位置信息）、广告互动信息\n" +
    "使用目的：程序化广告投放、广告监测归因、广告防欺诈与运营维护。\n" +
    "隐私政策链接：https://developer.huawei.com/consumer/cn/doc/HMSCore-Guides/whale-hong-kinetic-energy-sdk-privacy-statement-0000001658283582\n" +
    "\n" +
    "SDK名称：高德地图SDK\n" +
    "第三方主体：高德软件有限公司\n" +
    "使用目的：为App用户提供定位技术服务，查找附近的学校，提升用户体验；\n" +
    "收集的个人信息字段：\n" +
    "a.位置信息；\n" +
    "b.设备信息：MAC地址、IMEI、IMSI、MEID、硬件序列号、Android_id\n" +
    "c.传感器、计步器、陀螺仪信息\n" +
    "收集个人信息的目的：为了给应用返回用户的位置信息并供开发者基于用户的位置信息提供相应服务。为了追踪、排查服务中的各类问题\n" +
    "SDK隐私政策链接：https://lbs.amap.com/pages/privacy/\n" +
    "\n" +
    "SDK名称：微信OpenSDK\n" +
    "第三方主体：深圳市腾讯计算机系统有限公司 \n" +
    "使用目的：使用微信登录\n" +
    "处理方式：采取去标识、加密等方式进行传输和处理\n" +
    "收集个人信息类型：应用安装列表、用户选中的照片或视频 \n" +
    "隐私政策链接：https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8\n" +
    "\n" +
    "SDK名称：AGC 认证服务SDK（Android版）\n" +
    "第三方主体：华为软件技术有限公司\n" +
    "收集个人信息类型：设备信息（AAID）、用户信息（邮箱地址、手机号码、密码）、认证提供方信息（ProviderUid、头像、昵称、ProviderAccessToken）、AGC认证服务信息（AgcUid、头像、昵称、AgcAccessToken、AgcRefreshToken）、应用信息（应用包名、应用版本号）\n" +
    "使用目的：在认证提供方系统中最终用户的唯一标识，使最终用户可通过其在认证提供方系统中的标识来登录客户的应用，并在客户的应用中展示最终用户的个人信息。\n" +
    "隐私政策链接：https://developer.huawei.com/consumer/cn/doc/AppGallery-connect-Guides/agc-auth-android-sdksecurity-0000001053372658\n" +
    "\n" +
    "SDK名称：App 支付宝客户端 SDK\n" +
    "第三方主体：支付宝（中国）网络技术有限公司 \n" +
    "收集的个人信息类型：Android ID、OAID、SSID、BSSID；系统设置、系统属性、设备型号、设备品牌、操作系统；IP 地址、网络类型、运营商信息、Wi-Fi 状态、Wi-Fi 参数、Wi-Fi 列表。\n" +
    "使用目的：\n" +
    "保障用户账户和资金安全以及支付服务的安全稳定运行。\n" +
    "履行反洗钱、反恐怖融资、反电信网络诈骗等法定义务。\n" +
    "实现网络链路的选择和优化，以提升用户体验。\n" +
    "SDK 隐私说明链接：https://opendocs.alipay.com/common/02kiq3\n" +
    "\n" +
    "第三方SDK名称：华为统一扫码SDK（Android版）\n" +
    "第三方公司名称：华为软件技术有限公司\n" +
    "收集个人信息类型：您主动提交的数据（图片、文本）、传感器信息（加速度传感器、光照传感器）、网络信息、应用信息、设备信息、运营商信息（运营商的名称）、系统信息（系统设置、系统属性、设备型号、操作系统）和Wi-Fi信息（Wi-Fi状态）。\n" +
    "使用目的：为开发者应用提供码生成或扫码等能力，如果您只使用部分SDK功能，则可以根据实际功能进行裁剪。\n" +
    "隐私政策链接：https://developer.huawei.com/consumer/cn/doc/HMSCore-Guides/sdk-data-security-0000001050043971\n" +
    "\n" +
    "第三方SDK名称：DCloud(io.dcloud)\n" +
    "第三方公司名称：数字天堂（北京）网络技术有限公司\n" +
    "使用目的：提供统计分析服务，并通过应用启动数据及异常错误日志分析改进性能和用户体验，为用户提供更好的服务\n" +
    "收集的方式：自动收集\n" +
    "收集的个人信息类型：设备唯一识别码（IMEI/Android_ID/ANDROID ID/DEVICE_ID/IDFA、SIM序列号、OAID）\n" +
    "\n" +
    "第三方SDK名称：腾讯浏览服务sdk（com.tencent.smtt.sdk）\n" +
    "第三方公司名称：深圳市腾讯计算机系统有限公司\n" +
    "使用目的：提供浏览器服务。\n" +
    "收集的方式：自动收集\n" +
    "收集的信息：手机设备信息\n" +
    "隐私政策链接：https://x5.tencent.com/tbs/guide/develop.html\n" +
    "\n" +
    "第三方SDK名称：友盟统计分析SDK\n" +
    "第三方公司名称：友盟同欣（北京）科技有限公司\n" +
    "使用目的：进行APP运营统计与分析\n" +
    "收集的方式：自动收集\n" +
    "收集的信息：设备信息（IMEI/Android_ID/ANDROID ID/DEVICE_ID/IDFA、SIM序列号、OAID/地理位置信息\n" +
    "隐私权政策链接：https://www.umeng.com/page/policy\n" +
    "\n" +
    "\n" +
    "（四）其他\n" +
    "\n" +
    "请您理解，我们向您提供的服务是可能更新和发展的。如果您使用了前述说明中尚未涵盖的其他服务，基于该服务我们需要收集您的信息的，我们会通过页面提示、交互流程、协议约定或修订本声明的方式另行向您说明信息收集的范围和目的，并征得您的同意。\n" +
    "\n" +

    " <h2>（五）使用您身份证信息的情形\n" +
    "为了遵守相关法律法规并提供特定的服务功能，我们可能需要您提供身份证信息以完成实名认证。我们仅在以下情况下收集您的身份证信息：\n" +
    "- 实名注册或账户验证；\n" +
    "- 遵守反洗钱法规和其他法律要求。\n" +
    "我们承诺采取以下措施保护您的身份证信息安全：\n" +
    "- 使用行业标准的加密技术对您的身份证信息进行加密存储；\n" +
    "- 限制对身份证信息的访问，确保只有授权人员在必要时才能访问；\n" +
    "- 定期对数据安全措施进行审查和更新。\n" +
    "您有权随时访问、更正或删除您的身份证信息。如需行使这些权利，请联系我们的客服团队。\n" +
    "我们不会在未经您同意的情况下将您的身份证信息共享给任何第三方，除非是为了提供服务或满足法律要求。如果您的身份证信息需要被转移至其他国家或地区，我们将确保接收方遵守同等级别的数据保护标准。\n" +
    "（六）使用您银行账号、支付宝账号信息的情形\n" +
    "为了提供安全、便捷的退款服务，我们可能需要您提供银行账号、支付宝账号信息。我们仅在以下情况下收集您的信息：\n" +
    "- 处理您的退款；\n" +
    "- 遵守反洗钱法规和其他法律要求。\n" +
    "我们承诺采取以下措施保护您的信息安全：\n" +
    "- 使用行业标准的加密技术对您的信息进行加密存储和传输；\n" +
    "- 通过安全的支付网关处理所有支付事务。\n" +
    "您有权随时访问、更正、删除或撤回您对账号信息的同意。如需行使这些权利，请通过我们的客户服务渠道与我们联系。\n" +
    "我们不会在未经您同意的情况下将您的账号信息共享给任何第三方，除非是为了完成支付处理或满足法律要求。我们确保所有第三方服务提供商都遵守与我们同等的数据保护标准。\n" +

    "（六）使用您网页浏览信息的情形\n" +
    "为了改进我们的服务，我们可能会收集您的网页浏览信息。以下是我们收集和使用这些信息的具体情况：\n" +
    "- 我们收集的信息包括但不限于：访问的页面、搜索查询、停留时间、点击模式等。\n" +
    "- 我们使用网页浏览信息来：\n" +
    "- 分析用户行为，以优化我们的服务和产品。\n" +
    "我们承诺采取以下措施来保护您的网页浏览信息：\n" +
    "- 使用加密技术保护数据的传输和存储；\n" +
    "- 限制对网页浏览信息的访问，确保只有授权人员在必要时才能访问；\n" +
    "- 定期对数据保护措施进行审查和更新。\n" +
    "您可以随时通过我们的隐私设置访问、更正或删除您的网页浏览信息。如需进一步控制我们如何使用您的网页浏览信息，或撤回您的同意，请通过以下联系方式与我们联系。\n" +
    "我们不会在未经您同意的情况下将您的网页浏览信息共享给任何第三方，除非是为了提供服务或满足法律要求。我们确保所有第三方服务提供商都遵守与我们同等的数据保护标准。</h2>\n" +

    "二、信息使用\n" +
    "\n" +
    "（一）一般来说，菁彩校园出于以下目的使用您的个人信息\n" +
    "\n" +
    "1、帐号管理：您可以通过菁彩校园创建帐号，我们将使用您提供的信息来管理您的帐号，并为您提供一系列的实用功能，您可以使用您的帐号进行多种操作，如管理订单、查看历史订单信息。\n" +
    "\n" +
    "2、与您联系：回复或处理您所提出的疑问或要求、发送与订单相关的信息（如订单完成提醒、空闲提醒）。\n" +
    "\n" +
    "3、提升服务的安全性和可靠性：我们可能会检测和预防欺诈行为、不法活动，将您的个人数据用于风险评估和安全目的。\n" +
    "\n" +
    "4、控制信用风险：为了确保交易安全和控制信用风险，菁彩校园及关联公司、业务合作伙伴可能对用户信息进行数据分析，并对上述分析结果进行商业利用。\n" +
    "\n" +
    "5、日常运营：包括但不限于订单管理、客户验证、技术支持、网络维护、故障排除、内部行政事务及内部政策和程序、生成内部报告等。\n" +
    "\n" +
    "6、法律目的：我们可能需要使用您的信息来处理和解决法律纠纷，或遵循对我们具有约束力的任何法律法规或监管机构颁发的文件规定，以配合国家部门或监管机构调查和遵从法律法规的目的。\n" +
    "\n" +
    "此外，我们可能会出于其他目的使用和披露您的个人信息，并通过修订本声明的形式告知您。\n" +
    "\n" +
    "您充分知晓，以下情形我们使用个人信息无需征得您的授权同意：\n" +
    "\n" +
    "① 与国家安全、国防安全有关的；\n" +
    "\n" +
    "② 与公共安全、公共卫生、重大公共利益有关的；\n" +
    "\n" +
    "③ 与犯罪侦查、起诉、审判和判决执行等有关的；\n" +
    "\n" +
    "④ 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；\n" +
    "\n" +
    "⑤ 所收集的个人信息是个人信息主体自行向社会公众公开的；\n" +
    "\n" +
    "⑥ 从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；\n" +
    "\n" +
    "根据您的要求签订合同所必需的；\n" +
    "\n" +
    "（8）用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；\n" +
    "\n" +
    "（9）为合法的新闻报道所必需的；\n" +
    "\n" +
    "（10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；\n" +
    "\n" +
    "（11）法律法规规定的其他情形。\n" +
    "\n" +
    "三、信息共享、转让及公开披露\n" +
    "\n" +
    "（一）信息共享\n" +
    "\n" +
    "我们可能会向合作伙伴等第三方共享您的订单信息、账户信息、设备信息以及位置信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴包括以下类型：\n" +
    "\n" +
    "业务合作伙伴：我们可能与合作伙伴一起为您提供产品或者服务，包括市场推广、广告投放、技术服务、实名认证服务、咨询服务等。\n" +
    "\n" +
    "关联公司：我们可能会与我们的关联公司共享您的个人信息，使我们能够向您提供其他产品或服务的信息，他们会采取不低于本隐私政策同等严格的保护措施。\n" +
    "\n" +
    "请您注意基于业务合作伙伴和关联公司防范和检测欺诈的目的，我们可能也会审慎地提供必要的个人信息，这些个人信息的提供会经过严格的内部安全评估和审批并通过签署相应的协议限定这些信息的使用目的。\n" +
    "\n" +
    "委托处理：本业务功能中某些具体的模块或功能由外部供应商提供。例如我们会聘请服务提供商来协助我们提供客户支持。对我们委托处理个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的要求、本个人信息保护政策以及其他任何相关的保密和安全措施来处理个人信息。\n" +
    "\n" +
    "为了您能够使用第三方支付、在第三方平台登录及分享信息、使用地图服务等服务及功能，我们的应用中会嵌入授权合作伙伴的软件工具开发包(SDK)或类似其他应用程序，我们通过操作系统提供接口调用的方式使用这些SDK，我们会对授权合作伙伴的SDK进行严格的安全技术检测和准入控制。对于我们与之共享个人信息的公司、组织我们会与其签署严格的保密协议，要求他们按照本隐私政策以及我们其他相关的保密和安全措施来处理个人信息。我们的合作伙伴无权将共享您的个人信息用于任何其他用途，如要改变个人信息的处理目的，将再次征求您的授权同意。\n" +
    "\n" +
    "（二）转让\n" +
    "\n" +
    "我们不会将您的个人信息转让给任何公司、组织和个人，但以下情形除外：\n" +
    "\n" +
    "（1）在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；\n" +
    "\n" +
    "（2）根据适用的法律法规、法律程序的要求、强制性的行政或司法要求；\n" +
    "\n" +
    "（3）在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此个人信息保护政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。\n" +
    "\n" +
    "（三）公开披露\n" +
    "\n" +
    "我们仅会在以下情形，公开披露您的个人信息：\n" +
    "\n" +
    "根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；\n" +
    "\n" +
    "根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。\n" +
    "\n" +
    "四、信息安全与保护\n" +
    "\n" +
    "（一）我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，在您的浏览器与 “服务”之间交换数据时受SSL加密保护；我们对产品提供的配套Web服务提供HTTPS 安全访问方式；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。\n" +
    "\n" +
    "（二）我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们承诺将使您的个人信息存储时间始终处于法律要求或实现产品功能和服务所需的合理必要的期限内。对于超出期限的个人信息，我们会立即删除或做匿名化处理。\n" +
    "\n" +
    "（三）互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的帐号安全。\n" +
    "\n" +
    "（四）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。\n" +
    "\n" +
    "（五）在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取 的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。\n" +
    "\n" +
    "五、用户个人信息管理\n" +
    "\n" +
    "您对您的个人信息享有以下权利:\n" +
    "\n" +
    "（一）您有权访问、更正、删除您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式管理您的信息:\n" +
    "\n" +
    "账户信息——您可以通过修改微信昵称和头像来更新小程序内个人信息。\n" +
    "\n" +
    "如果您无法通过上述链接管理这些个人信息，您可以随时通过本隐私政策中提供的反馈方式联系我们。我们将在15个工作日内回复您的访问请求。\n" +
    "\n" +
    "（二）注销账户\n" +
    "\n" +
    "您有权注销您的菁彩校园账户，您可以通过我们的客服或通过本隐私政策提供的其他方式与我们取得联系。为了保障安全，我们可能需要您提供相应方式证明您的身份和请求的正当性，我们将在收到您反馈并验证您的身份后的15天内答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。\n" +
    "\n" +
    "在以下情形中，我们将无法响应您的请求：\n" +
    "\n" +
    "（1）与个人信息控制者履行法律法规规定的义务相关的；\n" +
    "\n" +
    "（2）与国家安全、国防安全直接相关的；\n" +
    "\n" +
    "（3）与公共安全、公共卫生、重大公共利益直接相关的；\n" +
    "\n" +
    "（4）与刑事侦查、起诉、审判和执行判决等直接相关的；\n" +
    "\n" +
    "（5）个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；\n" +
    "\n" +
    "（6）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；\n" +
    "\n" +
    "（7）响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；\n" +
    "\n" +
    "（8）涉及商业秘密的。\n" +
    "\n" +
    "六、未成年人信息保护\n" +
    "\n" +
    "我们的产品和服务主要面向成年人。对于经法定监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、法定监护人明确同意或者保护未成年人所必要的情况下使用或公开披露合法收集到的未成年人的个人信息。\n" +
    "\n" +
    "如果我们发现在未事先获得法定监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。如果法定监护人有理由相信未成年人未经他们事先同意而向菁彩校园平台提交了个人信息，请联系我们以确保删除此类个人信息，并保证未成年人取消订阅任何适用的菁彩校园服务。\n" +
    "\n" +
    "七、隐私政策的更新\n" +
    "\n" +
    "为向您提供更好的服务和更优质的用户体验，本政策会根据业务调整、法律法规或政策的变化而发生适时变更。我们将标注本隐私政策最近更新的日期，更新将于发布时生效。未经您明确同意，菁彩校园不会削减您按照本隐私权政策所应享有的权利。对于重大变更，菁彩校园还会提供更为显著的通知。请您经常回访本隐私政策，以阅读最新版本。\n" +
    "\n" +
    "本政策所指的重大变更包括但不限于：\n" +
    "\n" +
    "（1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；\n" +
    "\n" +
    "（2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；\n" +
    "\n" +
    "（3）个人信息共享、转让或公开披露的主要对象发生变化；\n" +
    "\n" +
    "（4）您参与个人信息处理方面的权利及其行使方式发生重大变化；\n" +
    "\n" +
    "（5）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；\n" +
    "\n" +
    "（6）个人信息安全影响评估报告表明存在高风险时。\n" +
    "\n" +
    "八、如何联系我们\n" +
    "\n" +
    "如您有任何与个人信息保护或隐私保护相关的问题、意见或建议。您可以拨打400-700-5199与我们联系。\n" +
    "\n" +
    "一般情况下，我们会在十五个工作日内回复，如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信办、消费者保护协会等监督部门进行投诉，投诉方式如下：\n" +
    "\n" +
    "国家网信办举报中心：www.12377.cn\n" +
    "\n" +
    "消费者投诉举报电话：12315\n" +
    "\n" +
    "您知晓，菁彩校园帐号存在权属争议的，菁彩校园有权禁用帐号，直至争议双方通过和解或司法途径解决并向菁彩校园提供有效证明。在此期间菁彩校园有权拒绝帐号注销、换绑手机号等操作。\n" +
    "\n" +
    "注销本菁彩校园账户并不代表本菁彩校园账户注销前的账户行为和相关责任得到豁免或减轻。\n" +
    "\n" +
    "菁彩校园平台\n" +
    "发布日期：2024年01月31日\n" +
    "生效日期：2024年01月31日"